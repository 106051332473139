/* eslint-disable  */ 

import React from 'react';
import classNames from 'classnames/bind';
import styles from './HandlePopUp.css';

const cx = classNames.bind(styles);

export default function EndButton(props) {
  if (props.isHost) {
    return (
      <button
        className={cx('btn-end')}
        onClick={() => {
          props.onEnd();
        }}
      >
        End Meeting
      </button>
    );
  }
  return null;
}
