/* eslint-disable  */

// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import classNames from 'classnames/bind';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import Modal from 'react-modal';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import routes from '../constants/routes.json';

import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import getChimeContext from '../context/getChimeContext';
import getMeetingStatusContext from '../context/getMeetingStatusContext';
import MeetingStatus from '../enums/MeetingStatus';
import ViewMode from '../enums/ViewMode';
import ChatPvt from './ChatPvt';
import styles from './Classroom.css';
import ContentVideo from './ContentVideo';
import Controls from './Controls';
import DeviceSwitcher from './DeviceSwitcher';
import Error from './Error';
import LoadingSpinner from './LoadingSpinner';
import RemoteVideoGroup from './RemoteVideoGroup';
import Roster from './Roster';
import ScreenShareHeader from './ScreenShareHeader';
import AddPeopleToMeeting from './AddPeopleToMeeting';
import FileShare from './FileShare';
import commonob from '../constants/common.json';
import FormStack from './FormStack';
import { useHistory, useLocation } from 'react-router-dom';
import MessageType from '../types/MessageType';
import videojs from 'video.js'


var request = require('request');
const cx = classNames.bind(styles);

export default function Classroom() {
  Modal.setAppElement('body');

  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  const { meetingStatus, errorMessage } = useContext(getMeetingStatusContext());

  const videoRefLiveStreaming = useRef(null);
  const videoRefBroadCast = useRef(null);
  const sourceRefLiveStreaming = useRef(null);
  const sourceRefBroadCast = useRef(null);

  const [viewMode, setViewMode] = useState(ViewMode.Room);
  const [tab, setTab] = useState(10);
  const [docs, setDocs] = useState([])
  const [minmax, setMinMax] = useState(false);
  const [videosrc, setVideoSrc] = useState("");
  const [tabload, setTabLoad] = useState(false);
  const [showImg, setshowImg] = useState(null);
  const [menuTab, setMenuTab] = useState(false);
  const [videoTitle, setVideoTitle] = useState("");
  const [vodMediaList, setvodMediaList] = useState([]);
  const [smallscreen, setSmallScreen] = useState(false);
  const [activeAttendee, setActiveAttendee] = useState("");
  const [messageCounter, setMessageCounter] = useState(0);
  const [messagePopup, setMessagePopup] = useState([]);
  const [showLoading, setShowLoading] = useState(false)
  const [showExtraIcon, setShowExtraIcon] = useState(false)
  const [isRightBarEnabled, setIsRightBarEnabled] = useState(false);
  const [liveStreamingData, setLiveStreamingData] = useState("")
  const [isContentShareEnabled, setIsContentShareEnabled] = useState(false);
  const [isEnableCopyMeeting, setIsEnableCopyMeeting] = useState(false);

  const query = new URLSearchParams(useLocation().search);
  const logo_name = commonob.app_name
  const [meetingId, setMeetingId] = useState(query.get("title") ? query.get("title") : "");
  const history = useHistory();

  let docLink = [
    { uri: showImg },
  ];

  // The below code is deny to join meeting,if user use same url multiple time  
  useEffect(() => {

    // var invitedUrl = localStorage.getItem("meeting_url")
    // if (window.performance) {
    //   if (performance.navigation.type == 1) {
    //   } else {
    //     if (invitedUrl != null) {
    //       if (invitedUrl.includes(`${commonob.web_url}/classroom?title=`)) {
    //         chime?.leaveRoom(false);
    //         localStorage.setItem('meeting_url', null)
    //         history.push(routes.HOME);
    //       }
    //     }
    //   }
    // }

    (async function getMedia() {
      let stream = null;
      let permission = { audio: true, video: true };
      try {

        /* use the stream */
        stream = await navigator.mediaDevices.getUserMedia(permission);

      } catch (err) {

        /* handle the error */
        console.log("err===", err)

      }
    })()

  }, [])

  // The below code is when user click on back button on browser 
  // useEffect(() => {
  //   window.history.pushState(null, "meeting", location.href);
  //   window.addEventListener('popstate', function (event) {
  //     var remove = confirm("Are you sure to want leave meeting?");
  //     if (remove == true) {
  //       chime?.leaveRoom(true);
  //       localStorage.setItem('meeting_url', null)
  //       history.push(routes.HOME);
  //       window.location.reload(false)
  //     } else {
  //       window.history.pushState(null, "meeting", location.href);
  //     }
  //   }, false);
  // }, [])


  function deleteMessage(msg, index) {
    if (messagePopup.length > 0) {
      setMessagePopup((prevMsg) => {
        return (
          prevMsg.filter(fltMsg => fltMsg.time != msg.time)
        )
      })
    }
  }

  function setLocalStorage() {
    let lsRight = localStorage.getItem("isRightBarEnabled");
    if (lsRight == null) {
      localStorage.setItem("isRightBarEnabled", 'true');
    }
    else {
      localStorage.setItem("isRightBarEnabled", lsRight == 'false' ? 'false' : 'true');
    }
  }


  async function setTabId(tabid: number) {
    if (tab != tabid) {
      localStorage.setItem("isRightBarEnabled", 'true');
      setTab(tabid);
      setIsRightBarEnabled(true);
      setLocalStorage();
    }
    else {
      if (isRightBarEnabled == false || localStorage.getItem("isRightBarEnabled") == "false") {
        localStorage.setItem("isRightBarEnabled", 'true');
        setTab(tabid);
        setIsRightBarEnabled(true);
        setLocalStorage();
      } else {
        localStorage.setItem("isRightBarEnabled", 'false');
        setTab(10);
        setIsRightBarEnabled(false);
        setLocalStorage();
      }
    }

  }

  // get selected videos list when meeting create
  function getVODData(reload = false) {
    setShowLoading(true);
    var options = {
      'method': 'POST',
      'url': commonob.getBaseUrl + 'vods?media_type=vod',
      'json': true
    };

    try {
      request(options, async function (error, response) {
        if (error) console.log(error)
        else {
          let d = (response.body.public);
          if (d.length > 0) {
            const new_vod = await new Promise(async (resolve) => {
              //  method to get vod data from meeting api
              let da = await fetchDataFromMeetingAPI();
              resolve(da);
            })

            if (new_vod != undefined) {
              // concat and set with common vod and meeting vod array
              await setvodMediaList(d.concat(new_vod));
            }
            else {
              // set only common vod 
              await setvodMediaList(d);
            }
            // set default play video 
            if ((videosrc == '' || reload == true) && videoRefBroadCast.current != null) {
              videoRefBroadCast.current.load();
              // videoRefBroadCast.current.play();
              var player = videojs(videoRefBroadCast.current);
              player.src({
                src: commonob.mux.stream_url + d[0].playbackId + ".m3u8",
                type: commonob.mux.video_type
              });
              setVideoSrc(commonob.mux.stream_url + d[0].playbackId + ".m3u8");
              setVideoTitle(d[0].title);
            }
            setShowLoading(false);
          }
          setShowLoading(false);
        }
      });
    } catch {
      (err) => console.log(err);
    }

  }

  // get livestreaming data (all)
  function getLiveStreamingData() {

    var options = {
      'method': 'POST',
      'url': commonob.getBaseUrl + 'vods?media_type=live_stream',
      'json': true
    };

    try {
      request(options, function (error, response) {
        if (error) console.log(error)
        else {
          let ls = (response.body.public);
          if (ls.length > 0) {
            if (ls[0]?.playbackId != '')
              setLiveStreamingData(ls[0]?.playbackId);
          }
        }
      });
    } catch {
      (err) => console.log(err);
    }

  }

  // get selected Document and videos when meeting create
  async function fetchDataFromMeetingAPI() {
    return await new Promise<void>(async resolve => {

      var options = {
        'method': 'GET',
        'url': commonob.getBaseUrl + 'meeting_schedule?meetingId=' + meetingId
      };

      try {
        return await request(options, function (error, response) {
          if (error) console.log(error)
          else {
            let ls = JSON.parse(response.body);

            // set state for docs
            if (ls.length > 0) {
              setDocs(ls[0].documents)
            }
            // set videos
            if (ls.length > 0 && ls[0].videos.length > 0) {
              let new_vod = ls[0].videos;
              resolve(new_vod);
            } else
              resolve()
          }
        });
      } catch {
        (err) => console.log(err);
      }
    })
  }

  useEffect(() => {

    if (tabload == false) {
      new Promise<void>(async (resolve, reject) => {
        // get vod common data 
        await getVODData();
        // get live stream data 
        await getLiveStreamingData();
        resolve()
      })
    }
  }, [setTabLoad])

  // set livestreaming data (all) and set video url
  const reloadVideo = async (loadLS: string = '') => {
    await getLiveStreamingData();
    await playLSVideo();
  };

  const playLSVideo = () => {

    if (liveStreamingData != '') {
      videoRefLiveStreaming.current.pause();
      videoRefLiveStreaming.current.load();
      var player = videojs(videoRefLiveStreaming.current);

      let myurl = commonob.mux.stream_url + liveStreamingData + ".m3u8";
      player.src({
        src: myurl,
        type: commonob.mux.video_type
      });
      player.on('error', function () {
        console.log(player.error());
      });
    } else
      console.info("No any live streaming found !!");
  }

  const reloadBroadCast = async (vsrc: string, psrc: string, title: string) => {
    videoRefBroadCast.current.pause();
    setShowLoading(true);
    setVideoSrc(vsrc);
    setVideoTitle(title);
    setTimeout(() => {
      setShowLoading(false);
      videoRefBroadCast.current.load();
      videoRefBroadCast.current.play()
      var player = videojs(videoRefBroadCast.current);
      player.src({
        src: vsrc,
        type: commonob.mux.video_type
      });
    }, 1000)
  }

  const videoElement = useRef(null);

  const stopContentShare = async () => {
    await new Promise(resolve => setTimeout(resolve, 200));
    try {
      chime?.audioVideo?.stopContentShare();
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    } finally {
      setViewMode(ViewMode.Room);
    }
  };

  // Must pass a memoized callback to the ContentVideo component using useCallback().
  // ContentVideo will re-render only when one dependency "viewMode" changes.
  // See more comments in ContentVideo.
  const onContentShareEnabled = useCallback(

    async (enabled: boolean) => {
      if (enabled && localStorage.getItem("isScreenShareEnabled") == "true") {
        localStorage.setItem("isScreenShareEnabled", "false")
        await stopContentShare();
      }
      if (enabled == true) {
        localStorage.setItem("contentshare", enabled ? 'true' : 'none');
        setIsEnableCopyMeeting(false)
        setSmallScreen(false);
      } else if (enabled == false) {
        localStorage.setItem("contentshare", "false");
        setSmallScreen(false);
      }
      localStorage.setItem("shareScreen", enabled ? 'true' : 'false')
      setIsContentShareEnabled(enabled);
    },
    [viewMode]
  );

  useEffect(() => {

    chime?.pubnub?.addListener({
      message: async (msg: any) => {
      }
    });

  })

  function setTabLocal(value) {
    localStorage.setItem("tab", value);
  }

  const [videoPalying, setVideoPalying] = useState(false);

  async function videoStartStream(e) {
    e.preventDefault();

    const videoFile = (videoRefBroadCast.current as unknown) as HTMLVideoElement
    if (navigator.userAgent.indexOf("Firefox") != -1) {
      // @ts-ignore
      const stream: MediaStream = await videoFile.mozCaptureStream();
      await chime?.audioVideo?.startContentShare(stream);
    } else {
      // @ts-ignore
      const stream: MediaStream = await videoFile.captureStream();
      await chime?.audioVideo?.startContentShare(stream);
    }

    setVideoPalying(true);
  }

  async function videoPauseStream(e) {
    e.preventDefault();
    setVideoPalying(false);
    stopContentShare();
    videoRefBroadCast.current.pause();

  }

  function secondsToTime(secs) {
    var hours = Math.floor(secs / (60 * 60));
    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);
    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);

    return (
      <div> {("0" + hours).slice(-2)} : {("0" + minutes).slice(-2)} : {("0" + seconds).slice(-2)}</div>
    )
  }

  return (
    <>
      <style>{`     
       .classroom::after{
        background-repeat: no-repeat;
        background-size: 160px 30px;
      }`}
      </style>
      <div
        style={{ alignItems: (meetingStatus === MeetingStatus.Loading) ? "center" : "flex-start" }}
        className={cx('classroom', {
          roomMode: viewMode === ViewMode.Room,
          screenShareMode: viewMode === ViewMode.ScreenShare,
          isContentShareEnabled,
          nologo: viewMode === ViewMode.ScreenShare
        })}
      >

        <div className={cx('newheader')}>

          <div className={cx('logo')}>
            <img src={`../${logo_name}_white_logo.png`} />
          </div>
          <div className={cx('rightside')}>

            <img onClick={() => { window.open(`${commonob.open_url}desktop&token=${localStorage.getItem("personalData")}`) }}
              src="/icons/admin-link.png" />

            <img style={{ height: '30px', width: '30px', }} onClick={() => setTabId(0)}
              src="/icons/settings-square-white.png" />

            <img onClick={() => setTabId(0)}
              src="/icons/setings-white.png" />

            <div className={cx('userlogo')}
              onClick={() => setMenuTab(!menuTab)}
            >
              {query.get('name')?.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g)?.join('')}
            </div>

          </div>

          <div style={{ display: menuTab ? 'block' : 'none' }} className={cx('menuTab')}>

            <div className={cx('subtab')}

              onClick={() => {
                window.localStorage.setItem("isLogin", (false).toString());
                window.localStorage.setItem("isAuthenticated", (false).toString());
                localStorage.clear();
                history.push(routes.LOGIN);
              }}
            > Logout </div>
          </div>

        </div>

        {
          <div className={cx('videoright')} style={{ display: ((tab === 3) && isRightBarEnabled == true && showLoading == false && viewMode !== ViewMode.ScreenShare) ? 'block' : 'none' }} >

            {
              liveStreamingData != '' && (
                <video
                  id="my-video"
                  className="video-js"
                  controls
                  preload="auto"
                  data-setup="{}"
                  width="300"
                  height="204"
                  ref={videoRefLiveStreaming} >
                  <source ref={sourceRefLiveStreaming} type="application/x-mpegURL" src={liveStreamingData} />
                </video>

              )}
          </div>
        }
        {
          <div className={cx('videoright')} style={{ display: ((tab === 4) && isRightBarEnabled == true && showLoading == false && viewMode !== ViewMode.ScreenShare && vodMediaList.length > 0) ? 'block' : 'none' }}>

            <video
              id="my-video1"
              className="video-js"
              controls={true}
              preload="auto"
              width="300"
              height="204"
              onPlay={videoStartStream}
              onPause={videoPauseStream}
              ref={videoRefBroadCast}
            >
              <source ref={sourceRefBroadCast} type="application/x-mpegURL" src={videosrc} />
            </video>
          </div>
        }

        {meetingStatus === MeetingStatus.Loading && <LoadingSpinner />}
        {meetingStatus === MeetingStatus.Failed && (
          <Error errorMessage={errorMessage} />
        )}
        {meetingStatus === MeetingStatus.Succeeded && (
          <>
            <>
              <div className={cx('mainDiv')}>
                <>
                  {(messagePopup.length > 0 && viewMode !== ViewMode.ScreenShare && messagePopup.find(item => item.type === 'file') && tab !== 2 &&
                    <div
                      style={{ "display": messagePopup.length > 0 ? "block" : "none" }}
                      className={cx("msgreceived-main")}>
                      {messagePopup.map((data: any, index: any) => {
                        return (
                          <div key={data.time} className={cx("msgreceived")}>

                            <div onClick={() => { deleteMessage(data, index) }} className={cx("msgdelete")}>
                              <i className="fas fa-times-circle"></i>
                            </div>
                            <div className={cx("msgreceived-title")}
                              onClick={async () => {
                                // set the localstorage variable for rightsideenable and tab
                                if (isRightBarEnabled == true) {
                                  await setIsRightBarEnabled(false)
                                }
                                else {
                                  await setIsRightBarEnabled(true)
                                }
                                setLocalStorage();
                                setTab(2);
                                setTabLocal('false')
                              }}
                            >
                              <strong> {data.name} </strong> shared a file: <br />
                              {data.message + "." + data.extension}
                            </div>
                          </div>
                        )
                      })
                      }
                    </div>
                  )}

                  {(messagePopup.length > 0 && viewMode !== ViewMode.ScreenShare && messagePopup.find(item => item.type === 'msg') && tab !== 1 &&
                    <div
                      style={{ "display": messagePopup.length > 0 ? "block" : "none" }}
                      className={cx("msgreceived-main")}>
                      {messagePopup.map((data: any, index: any) => {
                        return (
                          <div key={data.time} className={cx("msgreceived")}>

                            <div onClick={() => { deleteMessage(data, index) }} className={cx("msgdelete")}>
                              <i className="fas fa-times-circle"></i>
                            </div>
                            <div className={cx("msgreceived-title")}
                              onClick={async () => {
                                // set the localstorage variable for rightsideenable and tab
                                if (isRightBarEnabled == true) {
                                  await setIsRightBarEnabled(false)
                                }
                                else {
                                  await setIsRightBarEnabled(true)
                                }
                                setLocalStorage();
                                setTab(1);
                                setTabLocal('true');
                                setMessageCounter(0);
                              }}
                            >
                              <strong> {data.name + ": "} </strong>{data.message}
                            </div>
                          </div>
                        )
                      })
                      }
                    </div>
                  )}

                  {(messagePopup.length > 0 && viewMode !== ViewMode.ScreenShare && messagePopup.find(item => item.type === 'autoFocus') &&
                    <div
                      style={{ "display": messagePopup.length > 0 ? "block" : "none" }}
                      className={cx("msgreceived-main")}>
                      {messagePopup.map((data: any, index: any) => {
                        return (
                          <div key={data.time} className={cx("msgreceived")}>

                            <div onClick={() => { deleteMessage(data, index) }} className={cx("msgdelete")}>
                              <i className="fas fa-times-circle"></i>
                            </div>
                            <div className={cx("msgreceived-title")}>
                              {data.message}
                            </div>
                          </div>
                        )
                      })
                      }
                    </div>
                  )}
                </>

                <div className={cx("right-side-section")} style={{ "display": viewMode !== ViewMode.ScreenShare ? "block" : "none" }}>
                  <div className={cx(
                    {
                      minmaxClass: minmax === true && isRightBarEnabled === false,
                      minmaxClassLeft: minmax === true && isRightBarEnabled === true,
                    }
                  )}>
                    {
                      <RemoteVideoGroup
                        isSmallScreen={smallscreen}
                        viewMode={viewMode}
                        isContentShareEnabled={isContentShareEnabled}
                        isRightBarEnabled={isRightBarEnabled}
                        shareScreenSmall={
                          (ss: boolean) => {
                            setSmallScreen(ss);
                          }
                        }
                        setActiveAttendeeForChatParent={async (id: string) => {
                        }}
                      />
                    }
                  </div>
                </div>

                <div className={cx('left')}>

                  {viewMode === ViewMode.ScreenShare && (
                    <ScreenShareHeader onClickStopButton={stopContentShare} />
                  )}

                  <div className={cx('contentVideoWrapper')}>
                    <span className={cx({
                      contentVideoSmall: smallscreen && isContentShareEnabled,
                      contentVideoSmallWithFullscreenAndRightsideEnable: smallscreen && isRightBarEnabled && isContentShareEnabled
                    })}
                      onClick={
                        () => {
                          setSmallScreen(false);
                        }
                      }
                    >
                      <ContentVideo
                        isSmallScreen={smallscreen}
                        isContentShareEnabled={isContentShareEnabled}
                        onContentShareEnabled={onContentShareEnabled}
                        screenShareFullScreen={
                          (fss) => {
                          }
                        }
                      />
                    </span>
                  </div>
                </div>

                <div className={cx('localVideoWrapper', (
                  localStorage.getItem("isRightBarEnabled") == 'true' ? 'localVideoWrapper-resize' : ''
                ))}>

                  <Controls
                    title={meetingId}
                    viewMode={viewMode}
                    messageCounter={messageCounter}
                    isRightBarEnabled={isRightBarEnabled}
                    tab={tab}
                    isSmallScreen={smallscreen}
                    isContentShareEnabled={isContentShareEnabled}
                    onClickShareButton={async () => {
                      try {
                        await chime?.audioVideo?.startContentShareFromScreenCapture();
                        localStorage.setItem("isScreenShareEnabled", "true");
                      } catch (err) {
                        console.log("err.....", err)
                      }
                      if (videoPalying) {
                        if (confirm('Are you sure to stop video broadcasting and start screenshare ?')) {
                          videoPauseStream(event);
                        }
                      }
                    }
                    }
                    onClickChatButton={() => {
                      // set message counter to zero
                      setMessageCounter(0);
                    }}
                    showHideRightPanel={
                      async () => {
                        // set the localstorage variable for rightsideenable and tab
                        if (isRightBarEnabled == true || localStorage.getItem("isRightBarEnabled") == 'true') {
                          localStorage.setItem("isRightBarEnabled", "false");
                          await setIsRightBarEnabled(false)
                        }
                        else {
                          localStorage.setItem("isRightBarEnabled", "true");
                          await setIsRightBarEnabled(true)
                        }
                        setLocalStorage();
                      }
                    }
                    setTabToParent={
                      (tabid) => {
                        setTabId(tabid);
                        setTab(tabid);
                      }
                    }
                    onClikcEnableCopyMeeting={() => {
                      setIsEnableCopyMeeting(!isEnableCopyMeeting)
                    }
                    }
                  />

                </div>

                <div className={cx('right')} style={{ "display": ((isRightBarEnabled || localStorage.getItem("isRightBarEnabled") == 'true') && viewMode !== ViewMode.ScreenShare) ? "block" : "none" }}>
                  <div className={cx('tabs')}>
                    <div className={cx('tabs-heading')} >
                      <div className={cx('close-settings-button')}>
                        <img
                          src="/icons/close-settings-panel.png"
                          onClick={
                            () => {
                              localStorage.removeItem("isRightBarEnabled");
                              setIsRightBarEnabled(false);
                              setTab(10);
                            }
                          }
                        />
                      </div>

                      <div className={cx('meeting-id')}>
                        Meeting id: <strong> {chime?.title} </strong>
                      </div>
                    </div>

                    <div className={cx('tabsTitle')}>

                      {!showExtraIcon && (
                        <>
                          <div className={cx('tab')} >
                            {localStorage.getItem("isRightBarEnabled") && tab != 1 && messageCounter > 0 &&
                              <span className={cx('msgcounter1')}>{(messageCounter)}</span>
                            }
                            <img title="Chat" onClick={() => { setTab(1); setTabId(1); setTabLocal('true'); setMessageCounter(0); }}
                              src={
                                tab == 1 ? '/icons/chat-icon-active.png' : '/icons/chat-icon.png'
                              } />
                          </div>

                          <div className={cx('tab')}>
                            <img title="Upload document" onClick={async () => { setTab(2); setTabId(2); setTabLocal('false') }}
                              src={
                                tab == 2 ? '/icons/upload-doc-icon-active.png' : '/icons/upload-doc-icon.png'
                              }
                            />
                          </div>

                          <div className={cx('tab')}>
                            <img title="On demand video" onClick={() => {
                              setTab(4);
                              setTabId(4);
                              setTabLocal('false');
                              getVODData();
                              setTabLoad(true);
                            }}
                              src={
                                tab == 4 ? '/icons/video-playlist-icon-active.png' : '/icons/video-playlist-icon.png'
                              }
                            />
                          </div>


                          <div
                            className={cx('tab')} >
                            <img title="Live stream" onClick={
                              async () => {
                                await setTab(3);
                                await setTabId(3);
                                reloadVideo();
                                setTabLocal('false');
                              }
                            }
                              src={tab == 3 ? "/icons/live-streaming-active-green.png" : "/icons/live-streaming-off.png"}
                            />
                          </div>


                          <div className={cx('tab')} >
                            <img
                              title="Form list"
                              onClick={async () => {
                                setTab(6);
                                setTabLocal('false')
                              }}
                              src={tab == 6 ? "/icons/formstack-active.png" : "/icons/formstack.png"}
                            />
                          </div>
                          <div className={cx('tab')} ><img
                            title="Redirect to web admin"
                            onClick={() => {
                              window.open(`${commonob.open_url}desktop&token=${localStorage.getItem("personalData")}`)
                            }}
                            src="/icons/icon-link.png"
                          /></div>
                        </>

                      )}


                      {showExtraIcon &&
                        <>
                          <div className={cx('tab')} >
                            <img
                              title="Form list"
                              onClick={async () => {
                                setTab(6);
                                setTabLocal('false')
                              }}
                              src={tab == 6 ? "/icons/formstack-active.png" : "/icons/formstack.png"}
                            />
                          </div>
                          <div className={cx('tab')} ><img
                            title="Redirect to web admin"
                            onClick={() => {
                              window.open(`${commonob.open_url}desktop&token=${localStorage.getItem("personalData")}`)
                            }}
                            src="/icons/icon-link.png"
                          /></div>
                        </>
                      }
                    </div>
                  </div>

                  <div className={cx('tabsection')} style={{ display: (tab === 0) ? 'block' : 'none' }}>
                    <>
                      <div className={cx('tabtitle')}>
                        <h3>Audio/Video Device Settings</h3>
                      </div>

                      <div>
                        <DeviceSwitcher />
                      </div>
                    </>
                    <>
                      <div className={cx('tabtitle')}>
                        <h3>Attendee List</h3>
                      </div>
                      <Roster
                        publicChannel={commonob.pubnub.public_channel + "-" + meetingId}
                        viewMode={viewMode}
                      />
                    </>
                  </div>

                  <div className={cx('tabsection')} style={{ display: (tab === 6) ? 'block' : 'none' }}>
                    <div className={cx('tabtitle')}>
                      <h3>Documents</h3>
                    </div>

                    <div>
                      <FormStack docFS={docs} />
                    </div>
                  </div>


                  <div className={cx('tabsection')} style={{ display: (tab === 1) ? 'block' : 'none' }}>
                    <div className={cx('tabtitle')}>
                      <h3 className={cx('titlename')} > Chat  {activeAttendee !== "" && activeAttendee !== "all" ? <span> with {chime?.roster[activeAttendee].name} </span> : ""}  </h3>
                    </div>
                    <ChatPvt
                      title={query.get("title")}
                      tab={tab}
                      hideMessgePopup={
                        () => {
                          setMessagePopup([]);
                        }
                      }
                      messageCounter={
                        async (msgObj) => {

                          await setMessagePopup((oldData) => {
                            return (
                              [
                                {
                                  extension: msgObj.ext,
                                  type: msgObj.type,
                                  message: msgObj.message,
                                  name: msgObj.name,
                                  time: new Date().toLocaleString()
                                }
                              ]
                            )
                          });

                          // only show counter number on Icon if it's a msg
                          if (msgObj.type === 'msg') {
                            setMessageCounter((msgcount) => {
                              return msgcount + 1;
                            })
                          }
                        }
                      }
                      // newActiveAttendee={activeAttendee}
                      activeAttendeeGo={(key) => { setActiveAttendee(key) }}
                      documentMsgAdd={async (msg) => {
                        const realTimeMessages: MessageType[] = [];
                        realTimeMessages.push(msg);
                        await new Promise(resolve => setTimeout(resolve, 2000));
                      }}
                    />
                  </div>

                  <div className={cx('tabsection')} style={{ display: (tab === 3) ? 'block' : 'none' }}>
                    <div className={cx('tabtitle')}>
                      <h3> Live Streaming
              <a title="Reload live stream"
                          onClick={() => reloadVideo()}
                          className="link">
                          <i className="fas fa-sync" />
                        </a>
                      </h3>
                    </div>

                    <div className={cx('livestream-list')}>
                      {
                        liveStreamingData == "" && (
                          <div style={{ 'marginTop': '321px' }}>No live stream available now !</div>
                        )
                      }
                    </div>
                    <div>
                    </div>
                  </div>

                  <div className={cx('tabsection')} style={{ display: (tab === 4) ? 'block' : 'none' }}>
                    <div className={cx('tabtitle')}>
                      <h3> On Demand Video
            <a title="Reload video list" onClick={async () => {
                          await setVideoTitle('');
                          await setvodMediaList([]);
                          await getVODData(true)
                        }} className="link">
                          <i className="fas fa-sync" />
                        </a>
                      </h3>
                      <span className={cx("video-title")}>{videoTitle}</span>
                    </div>

                    {showLoading && (
                      <div className={cx("videoloading")}>
                        Loading video...
                      </div>
                    )
                    }
                    <div className={cx('odvmain')}>
                      {
                        vodMediaList.length == 0 && !showLoading &&
                        (
                          <div className={cx('livestream-list')}>
                            <div style={{ 'marginTop': '321px' }}>No any video found !</div>
                          </div>
                        )
                      }

                      {
                        vodMediaList.map((mdlist, index) => {
                          return (
                            <div key={index} className={cx('videolist')}
                              style={{
                                display: videosrc == commonob.mux.stream_url + mdlist.playbackId + ".m3u8" ? 'none' : 'block'
                              }}
                            >

                              <div
                                onClick={async () => {
                                  await reloadBroadCast(commonob.mux.stream_url + mdlist.playbackId + ".m3u8", commonob.mux.image_url + mdlist.playbackId + "/thumbnail.png?width=214&height=121&fit_mode=pad", mdlist.title)
                                }
                                }
                                className={cx('thumbnail')} >
                                <img src={commonob.mux.image_url + mdlist.playbackId + "/thumbnail.png?width=214&height=121&fit_mode=pad"} />
                              </div>

                              <div
                                onClick={() => {
                                  reloadBroadCast(commonob.mux.stream_url + mdlist.playbackId + ".m3u8", commonob.mux.image_url + mdlist.playbackId + "/thumbnail.png?width=214&height=121&fit_mode=pad", mdlist.title)
                                }
                                }
                                className={cx('title')}>
                                <div className={cx('titlename')}> {mdlist.title} </div>
                                <div> {
                                  secondsToTime(mdlist.duration)}</div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>

                  <div className={cx('tabsection')} style={{ display: (tab === 2) ? 'block' : 'none' }}>
                    <div className={cx('tabtitle')}>
                      <h3> File Sharing </h3>
                    </div>

                    <FileShare
                      child={query.get("child")}
                      key={tab}
                      tab={tab}
                      title={query.get("title")}
                      setshowImg_={(data: string) => { setshowImg(data) }}
                    />
                  </div>
                </div>
              </div> { /* mainDiv */}
            </>

            {showImg && (<div className={cx('popupBox')}>
              <div className={cx('popupClose')} onClick={() => setshowImg(null)}><i className="fas fa-times" /></div>
              {(() => {
                if (showImg.match(/.(pdf|doc|docx|xls|xlsx|ppt|pptx|jpg|jpeg|jpe|png|gif|bmp|tif|tiff|svg|txt)$/i)) {
                  return <div className={cx('popupFile')}><DocViewer style={{ height: '750px' }} documents={docLink} pluginRenderers={DocViewerRenderers} /></div>;
                } else if (showImg.match(/.(mp4|3gp|avi|flv|mov|mkv|mpeg|wmv|webm|mp3|wav|m4a|aac|aiff|amr)$/i)) {
                  return <div className={cx('popupImg')}>

                    <video ref={videoElement}
                      onPlay={videoStartStream}
                      width="450px" height="300px" controls >
                      <source src={showImg} type="video/mp4" />
                    </video>

                  </div>;
                }
              })()}

            </div>)}

            <Modal
              isOpen={isEnableCopyMeeting}
              contentLabel="Add people to meeting"
              className={cx('modal')}
              overlayClassName={cx('modalOverlay')}
              onRequestClose={() => {
                setIsEnableCopyMeeting(false)
              }}
            >
              <>
                <AddPeopleToMeeting
                  title={query.get('title')}
                  name={query.get('name')}
                  onClickCancelButton={
                    () => {
                      setIsEnableCopyMeeting(false)
                    }
                  }
                />
              </>
            </Modal>
          </>
        )}
      </div>
    </>
  );
}
