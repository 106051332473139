/* eslint-disable  */ 

import React from 'react';
import classNames from 'classnames/bind';
import styles from './HandlePopUp.css';
import EndButton from './EndButton';

const cx = classNames.bind(styles);

function HandlePopUp(props) {
  if (props.isOpen) {
    return (
      <>
        <div className={cx('diaplayPopUp')}>
          <EndButton
            isHost={props.isHost}
            onEnd={() => {
              props.onEnding();
            }}
          />
          <button
            className={cx('btn-leave')}
            onClick={() => {
              props.onLeave();
            }}
          >
            Leave Meeting
          </button>

          <button
            className={cx('btn-cancel')}
            onClick={() => {
              props.onCancel();
            }}
          >
            Cancel
          </button>
        </div>
      </>
    );
  }
  return null;
}

export default HandlePopUp;