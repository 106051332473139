/* eslint-disable */

// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import classNames from 'classnames/bind';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import routes from '../constants/routes.json';
import getChimeContext from '../context/getChimeContext';
import getUIStateContext from '../context/getUIStateContext';
import ClassMode from '../enums/ClassMode';
import ViewMode from '../enums/ViewMode';
import styles from './Controls.css';
import Tooltip from './Tooltip';
import HandlePopUp from './HandlePopUp';
import commonob from '../constants/common.json';

const cx = classNames.bind(styles);

enum VideoStatus {
  Disabled,
  Loading,
  Enabled
}

type Props = {
  tab: number;
  title: string
  viewMode: ViewMode;
  messageCounter: number;
  isSmallScreen: boolean;
  isRightBarEnabled: boolean;
  isContentShareEnabled: boolean;
  onClickShareButton: () => void;
  onClickChatButton: () => void;
  showHideRightPanel: () => void;
  setTabToParent: (id: number) => void;
  onClikcEnableCopyMeeting: () => void;
};

export default function Controls(props: Props) {
  const {
    tab,
    title,
    viewMode,
    messageCounter,
    setTabToParent,
    onClickShareButton,
    onClickChatButton,
    showHideRightPanel,
    onClikcEnableCopyMeeting,
  } = props;

  const intl = useIntl();
  const history = useHistory();

  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  const [state] = useContext(getUIStateContext());

  const [muted, setMuted] = useState(false);
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState(false);
  const [videoStatus, setVideoStatus] = useState(VideoStatus.Disabled);

  const publicChannel = commonob.pubnub.public_channel +"-"+title;
  const attendeeId = chime?.configuration?.credentials?.attendeeId;

  useEffect(() => {
    const callback = (localMuted: boolean) => {
      setMuted(localMuted);
    };
    chime?.audioVideo?.realtimeSubscribeToMuteAndUnmuteLocalAudio(callback);
    return () => {
      if (chime && chime?.audioVideo) {
        chime?.audioVideo?.realtimeUnsubscribeToMuteAndUnmuteLocalAudio(
          callback
        );
      }
    };
  }, []);
  
  return (
    <>
      <HandlePopUp
        isHost={chime?.host}
        isOpen={value}
        onCancel={() => setValue(!value)}
        onEnding={() => {
          chime?.leaveRoom(true);
          localStorage.setItem('meeting_url', null)
          history.push(routes.HOME);
        }}
        onLeave={() => {
          chime?.leaveRoom(false);
          localStorage.setItem('meeting_url', null)
          history.push(routes.HOME);
        }}
      />
      <div
        className={cx('controls', {
          roomMode: viewMode === ViewMode.Room,
          screenShareMode: viewMode === ViewMode.ScreenShare,
          videoEnabled: videoStatus === VideoStatus.Enabled,
          audioMuted: muted
        })}
      >

        <div className={cx('button-left-side')}>
          {viewMode !== ViewMode.ScreenShare && (
            <>
              <Tooltip
                tooltip={
                  intl.formatMessage({ id: 'Controls.AttendeeList' })
                }
              >
                <img
                  className={cx('float-left')}
                  onClick={async () => {
                    showHideRightPanel();
                    setTabToParent(0);
                  }}
                  src={tab == 0 ? '/icons/user-group-active.png' : '/icons/user-group.png'} />
              </Tooltip>

              <Tooltip
                tooltip={
                  intl.formatMessage({ id: 'Controls.AttendeeInvite' })
                }
              >
                <img
                  className={cx('float-left')}
                  onClick={onClikcEnableCopyMeeting}
                  src="/icons/user-plus.png" />
              </Tooltip>

              <Tooltip
                tooltip={
                  intl.formatMessage({ id: 'Controls.chatTooltip' })
                }
              >            
                  <img
                    className={cx('float-left')}
                    onClick={() => {
                      localStorage.setItem("tab","true");
                      showHideRightPanel();
                      onClickChatButton();
                      setTabToParent(1);
                    }}
                    src={
                      tab == 1 ? '/icons/chat-icon-active-green.png' : '/icons/chat-icon.png'
                    } />
              </Tooltip>

                  {/* // start */}
                  {messageCounter > 0 && (localStorage.getItem("isRightBarEnabled") == 'false' || localStorage.getItem("isRightBarEnabled") == null) &&
                    <span className={cx('msgcounter')}>
                      {(messageCounter)}
                    </span>
                  }
                  {messageCounter > 0 && localStorage.getItem("isRightBarEnabled") == 'true' && (localStorage.getItem("tab") == 'false' || localStorage.getItem("tab") == null) &&
                    <span className={cx('msgcounter')}>
                      {(messageCounter)}
                    </span>
                  }
                  {/* // end */}            
            </>
          )}


        </div>

        <div className={cx('button-center', (
          localStorage.getItem("isRightBarEnabled") == 'true' ? 'reset-margin-left' : ''
        ))}>


          {viewMode === ViewMode.ScreenShare && (
            <img
              onClick={async () => {
                if (muted) {
                  chime?.audioVideo?.realtimeUnmuteLocalAudio();
                } else {
                  chime?.audioVideo?.realtimeMuteLocalAudio();
                }
                // Adds a slight delay to close the tooltip before rendering the updated text in it
                await new Promise(resolve => setTimeout(resolve, 10));
              }}

              src={muted ? (
                '/icons/mic-mute.png'
              ) : (
                  '/icons/mic-unmute.png'
                )} />
          )}

          {viewMode !== ViewMode.ScreenShare && (
            <Tooltip
              tooltip={
                muted
                  ? intl.formatMessage({ id: 'Controls.unmuteTooltip' })
                  : intl.formatMessage({ id: 'Controls.muteTooltip' })
              }
            >
              <img
                onClick={async () => {
                  if (muted) {
                    chime?.audioVideo?.realtimeUnmuteLocalAudio();
                  } else {
                    chime?.audioVideo?.realtimeMuteLocalAudio();
                  }
                  // Adds a slight delay to close the tooltip before rendering the updated text in it
                  await new Promise(resolve => setTimeout(resolve, 10));
                }}

                src={muted ? (
                  '/icons/mic-mute.png'
                ) : (
                    '/icons/mic-unmute.png'
                  )} />
            </Tooltip>
          )}

          {viewMode === ViewMode.ScreenShare && (
            <img
              onClick={async () => {
                // Adds a slight delay to close the tooltip before rendering the updated text in it
                await new Promise(resolve => setTimeout(resolve, 10));
                if (videoStatus === VideoStatus.Disabled) {
                  setVideoStatus(VideoStatus.Loading);
                  try {
                    if (!chime?.currentVideoInputDevice) {
                      throw new Error('currentVideoInputDevice does not exist');
                    }
                    await chime?.chooseVideoInputDevice(
                      localStorage.getItem("video_default") != null && localStorage.getItem("video_default") != undefined ? JSON.parse(localStorage.getItem("video_default")) : chime?.currentVideoInputDevice
                    );
                    chime?.audioVideo?.startLocalVideoTile();
                    setVideoStatus(VideoStatus.Enabled);
                  } catch (error) {
                    // eslint-disable-next-line
                    console.error(error);
                    setVideoStatus(VideoStatus.Disabled);
                  }
                } else if (videoStatus === VideoStatus.Enabled) {
                  setVideoStatus(VideoStatus.Loading);
                  chime?.audioVideo?.stopLocalVideoTile();
                  setVideoStatus(VideoStatus.Disabled);
                }
              }}
              src={videoStatus === VideoStatus.Enabled ? (
                '/icons/video-on.png'
              ) : (
                  '/icons/video-off.png'
                )}
            />

          )}

          {viewMode !== ViewMode.ScreenShare && (
            <Tooltip
              tooltip={
                videoStatus === VideoStatus.Disabled
                  ? intl.formatMessage({ id: 'Controls.turnOnVideoTooltip' })
                  : intl.formatMessage({ id: 'Controls.turnOffVideoTooltip' })
              }
            >
              <img
                onClick={async () => {
                  // Adds a slight delay to close the tooltip before rendering the updated text in it
                  await new Promise(resolve => setTimeout(resolve, 10));
                  if (videoStatus === VideoStatus.Disabled) {
                    setVideoStatus(VideoStatus.Loading);
                    try {
                      if (!chime?.currentVideoInputDevice) {
                        throw new Error('currentVideoInputDevice does not exist');
                      }
                      await chime?.chooseVideoInputDevice(
                        localStorage.getItem("video_default") != null && localStorage.getItem("video_default") != undefined ? JSON.parse(localStorage.getItem("video_default")) : chime?.currentVideoInputDevice
                      );
                      chime?.audioVideo?.startLocalVideoTile();
                      setVideoStatus(VideoStatus.Enabled);
                    } catch (error) {
                      // eslint-disable-next-line
                      console.error(error);
                      setVideoStatus(VideoStatus.Disabled);
                    }
                  } else if (videoStatus === VideoStatus.Enabled) {
                    setVideoStatus(VideoStatus.Loading);
                    chime?.audioVideo?.stopLocalVideoTile();
                    setVideoStatus(VideoStatus.Disabled);
                  }
                }}

                src={videoStatus === VideoStatus.Enabled ? (
                  '/icons/video-on.png'
                ) : (
                    '/icons/video-off.png'
                  )}
              />
            </Tooltip>
          )}


          {state.classMode === ClassMode.Teacher && viewMode === ViewMode.Room && (
            <Tooltip
              tooltip={
                focus
                  ? intl.formatMessage({ id: 'Controls.turnOffFocusTooltip' })
                  : intl.formatMessage({ id: 'Controls.turnOnFocusTooltip' })
              }
            >
              <img onClick={() => {
                const newFocusState = !focus;
                let msg = {
                name: chime?.roster[attendeeId].name,
                payload: {
                  focus: newFocusState,
                  message: "mute-attendee"
                },
                timestampMs: Date.now(),
                type: "focus"
              }
              chime?.pubnub?.publish({ channel : publicChannel, message: msg})

              let msg1 = {
                name: chime?.roster[attendeeId].name,
                payload: {
                  attendeeId: chime?.configuration?.credentials?.attendeeId,
                  message: newFocusState
                  ? intl.formatMessage({ id: 'Controls.focusOnMessage' })
                  : intl.formatMessage({ id: 'Controls.focusOffMessage' })
                },
                timestampMs: Date.now(),
                type: "auto-focus"
             }
             chime?.pubnub?.publish({ channel : publicChannel, message: msg1})
                setFocus(newFocusState);
              }}
                src="/icons/auto-focus.png" />
            </Tooltip>
          )}

          {
            viewMode !== ViewMode.ScreenShare && (
              <Tooltip
                tooltip={intl.formatMessage({ id: 'Controls.shareScreenTooltip' })}
              >
                <img
                  onClick={() => {
                      onClickShareButton();
                  }}
                  src="/icons/screen-share-off.png"
                />

              </Tooltip>
            )}

        </div>
        <div className={cx('button-right-side')} >
          <div>
            <img className={cx('float-left')}
              onClick={() => {
                setValue(!value);
              }}
              src="/icons/signout.png"
            />
          </div>        
        </div>
      </div>
    </>
  );
}
