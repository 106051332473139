// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/* eslint-disable  */ 


import classNames from 'classnames/bind';
import React from 'react';
import { useIntl } from 'react-intl';
import ViewMode from '../enums/ViewMode';
import RosterAttendeeType from '../types/RosterAttendeeType';
import styles from './RemoteVideo.css';

const cx = classNames.bind(styles);

export enum Size {
  Small,
  Medium,
  Large
}

type Props = {
  size: Size;
  tileView: boolean;
  v_height: any;
  videoLength:any;
  volume: any;
  viewMode: ViewMode;
  isSmallScreen: boolean;
  activeSpeaker: boolean;
  boundAttendeeId: string;
  raisedHand?: boolean;
  isVideoEnable: boolean;
  isAttendeeEnable: boolean;
  isRightBarEnabled: boolean;
  isContentShareEnabled: boolean;
  rosterAttendee?: RosterAttendeeType;
  resetFullscreen1: () => void;
  rendervideotoparent1: (b: any) => void;
  setActiveAttendeeForChat: (id: string) => void;
};

export default function RosterLayout1(props: Props) {
  const intl = useIntl();
  const {
    tileView,
    videoLength,
    volume,
    viewMode,
    raisedHand,
    isVideoEnable,
    isAttendeeEnable, 
    activeSpeaker,
    size = Size.Large,
    rosterAttendee = {}, 
    isContentShareEnabled,
    rendervideotoparent1,
    resetFullscreen1,
  } = props;

  var { name } = rosterAttendee;
  const initials = name?.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g)?.join('');

  return (
    <>
      <div
        style={{ backgroundColor: '#021b56' }}
        className={cx('attendee', 'remoteVideo', {
          screenShareMode: viewMode === ViewMode.ScreenShare,
          enabled: isVideoEnable == false,
          small: size === Size.Small,
          medium: size === Size.Medium,
          large: size === Size.Large,
          isSpeakerActive: activeSpeaker && isContentShareEnabled || activeSpeaker && tileView ||  (activeSpeaker && !tileView && !isAttendeeEnable),
        })}
      >
        {/* ROSTER DESIGN FOR ALL VIEW */}
        <video
          muted
          style={{height: 
            (tileView ) && 
            '100%'}}
          className={cx('video')}
          onClick={
            () => {
              rendervideotoparent1(rosterAttendee)
            }
          }
        />

        {/* ROSTER DESIGN WHEN SCREEN SHARED AND ATTENDEE MUTE */}

        {!isAttendeeEnable && isContentShareEnabled && !tileView && (
          <>
            <div className={cx('attendeeName')}>
              {initials}
            </div>
            <div className={cx('participantStatus')}>
              <div className={cx('nameStatus')}>
                <div className={cx('name')}>
                  {rosterAttendee.name}
                </div>
                {
                  typeof rosterAttendee.muted === 'boolean' && (
                    <div className={cx('muted')}>
                      {rosterAttendee.muted ? (
                        <i className="fas fa-microphone-slash" />
                      ) : (
                          <i className="fas fa-microphone" />
                        )}
                    </div>
                  )
                }
              </div>
            </div>
          </>
        )
        }

        {/* ROSTER DESIGN FOR LEFT SIDE SECTION WHEN ATTENDEE MUTE */}

        {!isAttendeeEnable && !isContentShareEnabled && !tileView &&
          <>

            <div className={cx('attendeeName')}>
              {initials}
            </div>
            <div className={cx('participantStatus')}>
              <div className={cx('nameStatus')}>
                <div className={cx('name')}>
                  {rosterAttendee.name}
                </div>
                {
                  typeof rosterAttendee.muted === 'boolean' && (
                    <div className={cx('muted')}>
                      {rosterAttendee.muted ? (
                        <i className="fas fa-microphone-slash" />
                      ) : (
                          <i className="fas fa-microphone" />
                        )}
                    </div>
                  )
                }
              </div>
            </div>
          </>
        }

        {/* ROSTER DESIGN FOR FULL SCREEN VIEW */}

        {isAttendeeEnable && !isContentShareEnabled && !tileView &&
          <>
            <div className={cx('attendeeName1')}>
              {initials}
            </div>

            <div
              className={cx('participantStatus',
                { participantStatusNew: isAttendeeEnable }
              )}>
              <div className={cx('nameStatus')}>

              {/* VOLUME INDICATOR */}

              <div style={{ height: '26px', overflow: 'hidden'}} className={cx('firstdiv')}>
               {
                  <div style={{marginTop: '7px', float: 'left'}}>
                  <div style={{ height: '6px', bottom: '-6px', position: 'relative', background: volume >= 20 ?  '#0cca0c' : '#8a8d8a' }} className={cx('volumecontrol')}>&nbsp;</div>
                  <div style={{ height: '8px', bottom: '-4px', position: 'relative', background: volume >= 40 ?  '#0cca0c' : '#8a8d8a' }} className={cx('volumecontrol')}>&nbsp;</div>
                  <div style={{ height: '10px', bottom: '-2px', position: 'relative', background: volume >= 60 ?  '#0cca0c' : '#8a8d8a' }} className={cx('volumecontrol')}>&nbsp;</div>
                  <div style={{ height: '12px', bottom: '0px', position: 'relative', background: volume >= 80 ?  '#0cca0c' : '#8a8d8a' }} className={cx('volumecontrol')}>&nbsp;</div>
                  <div style={{ height: '14px', bottom: '2px', position: 'relative', background: volume >= 100 ?  '#0cca0c' : '#8a8d8a' }} className={cx('volumecontrol')}>&nbsp;</div>
                  { rosterAttendee.muted  && (
                    <div className={cx('volumecontrol','mutemic')}>&nbsp;</div>
                  )}                  
                  </div>
                }                
            <span><strong>{rosterAttendee.name}</strong></span>
            </div>

                <div onClick={() => {
                  resetFullscreen1()
                }}
                  className={cx('firstdiv')}>
                  <i className="fas fa-times-circle" />
                  <span>{intl.formatMessage({ id: 'RosterLayout.ExitZoom' })}</span>
                </div>
              </div>
            </div>
          </>
        }


       {/* ROSTER DESIGN FOR TILEVIEW */}

        { tileView && (
          <>
            <div 
          style={{top: 
            "41%",padding:'0%',
            fontSize:videoLength <= 1 ?"65px" :"30px"
          }}
          className={cx('attendeeName')}>
              {initials}
            </div>
            <div 
            style={{bottom:videoLength <= 5 ? '15px' : '10px'}}
            className={cx('participantStatus')}>
              <div 
              className={cx('nameStatus')}
              style={{height:videoLength <= 5 && "31px"}}>
                
                <div 
                className={cx('name')}
                style={ {fontSize:videoLength <= 5 && '20px',marginTop:videoLength <= 5 && '3px'}}>
                  {rosterAttendee.name}
                </div>

                {
                  typeof rosterAttendee.muted === 'boolean' && (
                    <div 
                    className={cx('muted')}
                    style={{fontSize:videoLength <= 5 && "20px"}}
                    >
                      {rosterAttendee.muted ? (
                        <i className="fas fa-microphone-slash" />
                      ) : (
                          <i className="fas fa-microphone" />
                        )}
                    </div>
                  )
                }
              </div>
            </div>
          </>
        )
        }

        {raisedHand && !tileView && (
          <div className={cx('raisedHand')}>
            <span
              role="img"
              aria-label={intl.formatMessage({
                id: 'RemoteVideo.raiseHandAriaLabel'
              })}
            >
              ✋
          </span>
          </div>
        )}
      </div>
    </>
  );
}
