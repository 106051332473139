// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/* eslint-disable  */ 

import React, { Fragment } from 'react';
import { render } from 'react-dom';
import { AppContainer as ReactHotAppContainer } from 'react-hot-loader';
import commonob from './constants/common.json'
import './app.global.css';
import Root from './components/Root';

const AppContainer = process.env.PLAIN_HMR ? Fragment : ReactHotAppContainer;
document.getElementById('title').innerHTML=commonob.title_name
 
document.addEventListener('DOMContentLoaded', () =>
  render(
    <AppContainer>
      <Root />
    </AppContainer>,
    document.getElementById('root')
  )
);
