// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/* eslint-disable  */

import styles from './CreateOrJoin.css';
import classNames from 'classnames/bind';
import React, { useContext, useEffect, useState } from 'react';
import Dropdown from 'react-dropdown';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import routes from '../constants/routes.json';
import getChimeContext from '../context/getChimeContext';
import getUIStateContext from '../context/getUIStateContext';
import ClassMode from '../enums/ClassMode';
import RegionType from '../types/RegionType';
import FormValidate from './FormValidate';
import commonob from '../constants/common.json'
import pkgJSON from '../package.json';
import UpcomingMeetings from "./UpcomingMeetings";
import localStorageKeys from '../constants/localStorageKeys.json';
import { userAuthenticate, setLogoutTimer, isEmpty, isEmail, isPhone } from '../common_function'
import ScheduleMeetingForm from "./ScheduleMeetingForm"

const cx = classNames.bind(styles);
var uuid = require('uuid');

export default function CreateOrJoin() {

  const chime = useContext(getChimeContext()) as ChimeSdkWrapper;
  const [state] = useContext(getUIStateContext());
  const [, dispatch] = useContext(getUIStateContext());
  const login_flag = localStorage.getItem("isAuthenticated");

  const history = useHistory();
  const intl = useIntl();
  const location = useLocation<History | undefined>();

  const [error, setError] = useState({});
  const [isEdit, setIsEdit] = useState(false)
  const [isJoin, setIsJoin] = useState(false)
  const [editId, setEditId] = useState('')
  const [signUpUrl, setSignUpUrl] = useState('')
  const [openScheduleMeetingForm, setOpenScheduleMeetingForm] = useState(false)
  const [sureToLogout, setSureToLogout] = useState(false);
  const [openScheduler, setOpenScheduler] = useState(false)
  const [isJoinFormEnabled, setIsJoinFormEnabled] = useState(false);
  const [isOPenWithDesktop, setIsOPenWithDesktop] = useState(false);
  const [title, setTitle] = useState(login_flag ? uuid.v1().substring(0, 8) : "");
  const [name, setName] = useState("");
  const [region, setRegion] = useState<RegionType | undefined>(undefined);


  var rand_id = uuid.v1().substring(0, 8);
  var logoutDate = (localStorage.getItem("dateTimeLogout") !== null || localStorage.getItem("dateTimeLogout") !== undefined) ? parseInt(localStorage.getItem("dateTimeLogout")) > new Date().getTime() ? 0 : 1 : 2;
  const removeItem = localStorageKeys.REMOVE_ITEMS;
  const logo_name = commonob.app_name
  const user_name = localStorage.getItem("userName")
  var invitedUrl = localStorage.getItem("meeting_url")

  // cload recording code
  useEffect(() => {

    if (invitedUrl != null) {
      let isRecordingUrl = invitedUrl.split("&")[1] == "recording=1" ? true : false;
      if (invitedUrl.includes("/?m=") && !isRecordingUrl) {
        setIsOPenWithDesktop(true)
      }
      if (invitedUrl.includes("/?m=") && isRecordingUrl) {
        var url = new URL(invitedUrl);
        const urlParams = new URLSearchParams(url.search);
        const meetingParam = urlParams.get('m');
        // localStorage.setItem("currentAtendeeName", "«Meeting Recorder»");
        history.push(`/classroom?title=${encodeURIComponent(meetingParam)}&name=«Meeting Recorder»&region=us-east-1`);
      }
    }

    if (location.state !== undefined) {
      setIsJoinFormEnabled(true)
    }

    (async () => {
      setRegion(await chime?.lookupClosestChimeRegion());
    })();
  }, []);

  useEffect(() => {
    // check the credentials from localStorage
    if ((user_name !== null && localStorage.getItem("userPass") !== null) && logoutDate === 1) {
      // request payload
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: user_name, password: localStorage.getItem("userPass"),app:commonob.app_name })
      };

      new Promise<void>(async (resolve) => {
        // user authenticate
        let data = await userAuthenticate(requestOptions);
        if (data?.success) {
          // set logout timer
          setLogoutTimer();
        } else {
          alert('You are sign out!\nPlease signin again!')
          // clear localStorage for logout
          localStorage.clear();
          history.push(routes.LOGIN);
        }
        // resolve promise
        resolve();
      })
    }

    // localStorage.clear();  
    removeItem.map((key) =>
      localStorage.removeItem(key)
    )
  }, [])

  async function signUpURL() {
    const baseURL = commonob.getBaseUrl
    const response = await fetch(`${baseURL}user/setting?app=${commonob.app_name}`)
    const res = await response.json();
    return res;
  }

  useEffect(() => {
    let email = user_name;
    let password = localStorage.getItem("userPass");
    // request payload
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ email: email, password: password,app: commonob.app_name })
    };
    new Promise(async (res) => {
      let newData = await signUpURL();
      setSignUpUrl(newData.signup_url + commonob.return_url)
      await userAuthenticate(requestOptions);

      res({})
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async (event: any) => {

    event.preventDefault();
    if (!login_flag)
      localStorage.clear();

    setError({})
    let error: any = {}
    if (FormValidate.isEmpty(title)) {
      error['title'] = `Invalid ${intl.formatMessage({ id: 'CreateOrJoin.titlePlaceholder' })}`
    }
    if (FormValidate.isEmpty(name)) {
      error['name'] = `Invalid ${intl.formatMessage({ id: 'CreateOrJoin.namePlaceholder' })}`
    }
    if (FormValidate.isEmpty(region)) {
      error['region'] = 'Invalid Region'
    }
    if (FormValidate.isEmpty(title, name, region)) {
      setError(error)
    } else {
      // localStorage.setItem("currentAtendeeName", name);
      history.push(`/classroom?title=${encodeURIComponent(title)}&name=${encodeURIComponent(name)}&region=${region.value}`);
    }
  }

  const showJoinForm = () => {
    localStorage.setItem(
      localStorageKeys.CLASS_MODE,
      ClassMode.Student
    );

    dispatch({
      type: 'SET_CLASS_MODE',
      payload: {
        classMode: ClassMode.Student
      }
    });
    if (isJoinFormEnabled == true) {
      setIsJoinFormEnabled(false)
      setIsOPenWithDesktop(false)
    }
    else {
      setIsJoinFormEnabled(true)
    }
  }
  // open upcoming meeting form
  const showMeetingForm = () => {
    if (openScheduler === true) {
      setOpenScheduler(false)
    }
    else {
      setOpenScheduler(true)
    }
  }

  // open schedule meeting form
  const showCreateMeetingForm = () => {
    if (openScheduleMeetingForm === true) {
      setOpenScheduleMeetingForm(false)
    }
    else {
      setOpenScheduleMeetingForm(true)
    }
  }

  // join meeting with desktop app
  const joinWithDesktop = () => {
    var url = new URL(invitedUrl);
    const urlParams = new URLSearchParams(url.search);
    const meetingParam = urlParams.get('m');
    document.location.href = commonob.app_name + '://meeting=' + meetingParam
  }

  // join meeting with web app
  const joinWithWeb = () => {
    var url = new URL(invitedUrl);
    const urlParams = new URLSearchParams(url.search);
    const meetingParam = urlParams.get('m');
    setTitle(meetingParam)
    setIsJoinFormEnabled(true)
    setIsOPenWithDesktop(false)
  }

  // download desktop app with diffrent system
  const downLoadDesktopApp = () => {
    var link = "";
    if (navigator.platform.indexOf('Mac') > -1) {
      link = commonob.deskTopApp_downloadUrl + ".dmg";
    }
    if (navigator.platform.indexOf('Win') > -1) {
      link = commonob.deskTopApp_downloadUrl + ".exe";
    }
    if (navigator.platform.indexOf('Lin') > -1) {
      alert("Application cooming soon ...")
    }
    if (link != "") {
      var a = document.createElement("a");
      a.setAttribute("download", commonob.app_name);
      a.setAttribute("href", link);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }
  return (
    <>
      {!sureToLogout && !isOPenWithDesktop &&
        <>
          {login_flag && !isJoinFormEnabled && !openScheduler && !openScheduleMeetingForm &&
            <div className={cx('createOrJoin')}>
              {
                <div className={cx('formWrapper')}>
                  <img className={cx('logo')} src={`/${logo_name}_logo.png`} />
                  <div className={cx('versionNumber')}>Version: {pkgJSON.version}</div>
                  <button className={cx('button')} type="button" onClick={async () => { await setTitle(""); await showJoinForm(); }}>Join</button>
                  <button
                    type="button"
                    style={{ fontSize: "16px" }}
                    className={cx('loginButton', 'loginButtonColor')}
                    onClick={async () => {
                      await localStorage.setItem(
                        localStorageKeys.CLASS_MODE,
                        ClassMode.Teacher
                      );

                      await dispatch({
                        type: 'SET_CLASS_MODE',
                        payload: {
                          classMode: ClassMode.Teacher
                        }
                      });

                      await setIsJoinFormEnabled(true);
                      await setTitle(rand_id);
                    }}
                  >
                    Start
            </button>

                  {
                    login_flag && <button className={cx('button')} type="button" onClick={async () => await showMeetingForm()}>Upcoming</button>
                  }
                  {
                    login_flag && (
                      <button
                        className={cx('loginButton', 'loginButtonColor')}
                        type="button"
                        onClick={async () => await showCreateMeetingForm()}
                      >Schedule
                      </button>
                    )
                  }

                  <div className={cx('login-bottom-link')}>
                    <div className={cx('margintop')} >
                      <div className={cx('loginLink', 'loginLinkBlackColor', 'rightSide')} onClick={() => {
                        setSureToLogout(true);
                      }}
                      >
                        <FormattedMessage id="UserLogout.title" />
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          }

          {
            !login_flag && !isJoinFormEnabled && !isOPenWithDesktop &&
            <div className={cx('createOrJoin')}>
              <div className={cx('formWrapper')}>
                <img className={cx('logo')} src={`/${logo_name}_logo.png`} />
                <div className={cx('versionNumber')}>Version: {pkgJSON.version}</div>
                <button className={cx('button')} type="button" onClick={() => showJoinForm()}>Join a Meeting</button>
                <button
                  type="button"
                  className={cx('loginButton', 'loginButtonColor')}
                  onClick={() => {
                    history.push(routes.LOGIN);
                  }}
                >
                  <FormattedMessage id="UserLogin.title" />
                </button>


                <div className={cx('login-bottom-link')}>
                  <br /><br />
                  <a target="_blank" style={{ float: "left" }} className={cx('loginLink')} href={signUpUrl}>
                    <FormattedMessage id="UserLogin.signup" />
                  </a>
                </div>
              </div>
            </div>
          }

          {openScheduler && login_flag && !isJoinFormEnabled && !isOPenWithDesktop &&
            <UpcomingMeetings
              joinMeetingFromList={
                (id) => {
                  setTitle(id)
                  setIsJoin(true)
                  setIsJoinFormEnabled(true)
                }
              }
              editMeetingFromList={
                (id: any) => {
                  setIsEdit(true)
                  setEditId(id)
                  setOpenScheduleMeetingForm(true)
                  setOpenScheduler(false)
                }
              }
            />
          }

          {openScheduleMeetingForm && login_flag && !isJoinFormEnabled &&
            <ScheduleMeetingForm
              isEdit={isEdit}
              meetingId={editId}
              goBack={() => {
                setIsEdit(false)
                setEditId('')
                setOpenScheduleMeetingForm(false)
                setOpenScheduler(true)
              }}
            />
          }


          {isJoinFormEnabled &&
            <div className={cx('createOrJoin')}>
              <div className={cx('formWrapper')}>
                <img className={cx('logo')} src={`/${logo_name}_logo.png`} />
                <div className={cx('versionNumber')}>Version: {pkgJSON.version}</div>
                <h1 className={cx('title')}>
                  {(login_flag && state.classMode === ClassMode.Teacher) ? (
                    <FormattedMessage id="CreateOrJoin.teacherTitle" />
                  ) : (
                    <FormattedMessage id="CreateOrJoin.studentTitle" />
                  )}
                </h1>
                <div className={cx('form')}>
                  <form onSubmit={handleSubmit}>
                    <input
                      value={title}
                      autoFocus={title == "" ? true : false}
                      className={cx('titleInput')}
                      placeholder={intl.formatMessage({
                        id: 'CreateOrJoin.titlePlaceholder'
                      })}
                      onChange={event => {
                        setTitle(event.target.value);
                      }}
                    />

                    {error['title'] ? <div className={cx('errorMessage')}>{error['title']}</div> : null}

                    <input
                      className={cx('nameInput')}
                      autoFocus={title == "" ? false : true}
                      onChange={event => {
                        setName((event.target.value));
                      }}
                      value={name}
                      placeholder={intl.formatMessage({
                        id: 'CreateOrJoin.namePlaceholder'
                      })}
                    />

                    {error['name'] ? <div className={('errorMessage')}>{error['name']}</div> : null}

                    {(login_flag && state.classMode === ClassMode.Teacher) && (
                      <div className={cx('regionsList')}>
                        <Dropdown
                          className={cx('dropdown')}
                          controlClassName={cx('control')}
                          placeholderClassName={cx('placeholder')}
                          menuClassName={cx('menu')}
                          arrowClassName={cx('arrow')}
                          value={region}
                          options={
                            region ? chime?.supportedChimeRegions : ([] as RegionType[])
                          }
                          disabled={!region}
                          onChange={(selectedRegion: RegionType) => {
                            setRegion(selectedRegion);
                          }}
                          placeholder=""
                        />
                      </div>
                    )}
                    <button className={cx('button')} type="submit">
                      <FormattedMessage id="CreateOrJoin.continueButton" />
                    </button>
                  </form>
                </div>


                <div className={cx('login-bottom-link')}>
                  <div>
                    <div className={cx('loginLink', 'loginLinkBlackColor', 'leftSide')} onClick={() => {
                      if (isJoin) {
                        setTitle('')
                        setIsJoin(false)
                        setIsJoinFormEnabled(false)
                        setOpenScheduler(true)
                        localStorage.setItem('meeting_url', null)
                      } else {
                        history.push(routes.JOIN)
                        localStorage.setItem('meeting_url', null)
                      }
                    }}
                    >
                      <FormattedMessage id="Back.link" />
                    </div>


                    {(login_flag && state.classMode === ClassMode.Teacher) && (
                      <div className={cx('loginLink', 'loginLinkBlackColor', 'rightSide')} onClick={() => {
                        setSureToLogout(true);
                      }}>
                        <FormattedMessage id="UserLogout.title" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          }
        </>
      }

      {
        isOPenWithDesktop &&
        <div className={cx('createOrJoin')}>
          <div className={cx('formWrapper')}>
            <img className={cx('logo')} src={`/${logo_name}_logo.png`} />
            <div className={cx('versionNumber')}>Version: {pkgJSON.version}</div>
            <div className={cx('joinMeetingText')}>How do you want to join your <br /> {commonob.title_name} meeting?</div>
            <div
              className={cx('joinWithDesktop')}
              onClick={() => downLoadDesktopApp()}
            >
              <div className={cx('continueWithParentClass')}>
                <div className={cx('continueWithChild1Class')}>
                  <img className={cx('downloadicon')} src={`/icons/apple.png`} />
                  <img className={cx('downloadicon1')} src={`/icons/windows.png`} />
                </div>
                <div className={cx('continueWithChild2Class')}>
                  <div className={cx('text1')}>Download the Desktop App</div>
                  <div className={cx('text2')}>Use the desktop app for a better <br /> experience</div>
                </div>
              </div>
            </div>
            <div
              className={cx('joinWithWeb')}
              onClick={() => joinWithWeb()}>
              <div className={cx('continueWithParentClass')}>
                <div className={cx('continueWithChild1Class')}>
                  <img className={cx('browsericon1')} src={`/icons/chrome.png`} />
                  <img className={cx('browsericon2')} src={`/icons/edge-microsoft.png`} />
                  <img className={cx('browsericon3')} src={`/icons/mozila.png`} />
                </div>
                <div className={cx('continueWithChild2Class')}>
                  <div className={cx('text1')}>Continue in the Web browser</div>
                  <div className={cx('text2')}>We <b>ONLY</b> support Microsoft Edge,<br /> Google Chrome and Firefox</div>
                </div>
              </div>
            </div>
            <div
              className={cx('joinWithDesktop')}
              onClick={() => joinWithDesktop()}>
              <div className={cx('continueWithParentClass')}>
                <div className={cx('continueWithChild1Class')}>
                  <img className={cx('lounchicon')} src={`/icons/${commonob.app_name}_app.png`} />
                </div>
                <div className={cx('continueWithChild2Class')}>
                  <div className={cx('text1')}>Continue in the Desktop App</div>
                  <div className={cx('text2')}>For a better experience <b>LAUNCH</b> your desktop app now.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      {sureToLogout &&

        <div className={cx('createOrJoin')}>
          {
            <div className={cx('formWrapper')}>
              <img className={cx('logo')} src={`/${logo_name}_logo.png`} />
              <div className={cx('versionNumber')}>Version: {pkgJSON.version}</div>
              <h1 style={{ fontSize: "1.2rem" }} className={cx('title')}> Are you sure you want to logout ? </h1>
              <div className={cx('login-bottom-link')}>
                <div className={cx('logout')}>
                  <div className={cx('logoutOptions', 'bgcolorLeftSide')} onClick={() => {
                    window.localStorage.setItem("isLogin", (false).toString());
                    window.localStorage.setItem("isAuthenticated", (false).toString());
                    localStorage.clear();
                    setIsJoinFormEnabled(false);
                    setIsOPenWithDesktop(false)
                    history.push(routes.LOGIN);
                  }} >
                    Yes
              </div>
                  <div className={cx('logoutOptions', 'bgcolorRightSide')}
                    onClick={() => {
                      setSureToLogout(false);
                    }}
                  >
                    No
              </div>

                </div>
              </div>
            </div>
          }
        </div>
      }
    </>
  );
}
