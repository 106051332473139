/* eslint-disable  */
// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import styles from './UpcomingMeetings.css';
import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import routes from '../constants/routes.json';
import commonob from '../constants/common.json'
import pkgJSON from '../package.json'
import localStorageKeys from '../constants/localStorageKeys.json';
import Tooltip from './Tooltip';


const cx = classNames.bind(styles);

type Props = {
  joinMeetingFromList: (b: string) => void;
  editMeetingFromList: (id: any) => void;
};

var currentPage = { "page": 1 }
var total_meeting: any;

export default function UpcomingMeetings(props: Props) {

  const login_flag = (localStorage.getItem("isAuthenticated") ? true : false);

  const intl = useIntl();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [postData, setPostData] = useState();

  const removeItem = localStorageKeys.REMOVE_ITEMS;
  const perPage: any = 5;
  const logo_name = commonob.app_name

  const {
    joinMeetingFromList,
    editMeetingFromList
  } = props;


  // GET ALL MEETING LIST
  const meetingList = async () => {
    let body = {
      "created_by": localStorage.getItem("userName")
    }
    let options = {
      method: 'POST',
      body: JSON.stringify(body)
    };
    return new Promise(async (resolve) => {
      setLoading(true)
      let response = await fetch(`${commonob.admin_url}/meeting_schedule/list`, options)
      let res = await response.json()
      let list = res.Items
      setLoading(false)
      resolve(list);
    })
  }

  useEffect(() => {
    handlePageClick()
    removeItem.map((key) =>
      localStorage.removeItem(key)
    )
  }, [])

  // DELETE MEETING 
  const deleteMeeting = async (id: any, title: any) => {
    setLoading(true)
    let r = confirm(`${intl.formatMessage({
      id: 'CreateOrJoin.deleteMeetingConfirmation'
    })} : ${title}`);
    if (r == true) {
      setLoading(true)
      const requestOptions4 = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': commonob.nylas.credential
        }
      }

      const nylas_response = await fetch(`${commonob.nylas.deleteAPI}${id}`, requestOptions4)
      const response = await fetch(commonob.admin_url + `/meeting_schedule?event_id=${id}`, { method: 'DELETE' })

      if (nylas_response.ok && response.ok) {
        await handlePageClick()
        setLoading(false)
      } else {
        alert(intl.formatMessage({
          id: 'CreateOrJoin.errorMessage'
        }))
        setLoading(false)
      }
    }else{
      setLoading(false)
    }

  }

  // CHANGE PAGE
  const handlePageClick = async () => {
    function timer(time: any) {
      let date = new Date(time * 1000);
      let meet_time = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
      let meet_date = date.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'short', year: 'numeric'
      }).replace(/ /g, ' ');

      return (<><p className={cx("date_time")}>{meet_date}</p><p className={cx("date_time")}>{meet_time}</p></>)
    }

    let list: any = await meetingList()
    let exp_meeting: any = [];


    // CHECK. IS MEETING EXPIRED?
    list.map((key: any) => {
      let countDownDate = key.to_ts * 1000;
      let now = new Date().getTime();
      let cT = countDownDate - now;
      if (cT > 0) {
        exp_meeting.push(key)
      }
    })
    total_meeting = exp_meeting.length;
    // SLICE MEETING LIST PER PAGE 5 MEETING
    let slice = exp_meeting.slice((currentPage.page * perPage) - perPage, currentPage.page * perPage)
    let post_Data = slice.map((pd: any, index: any) =>
      <React.Fragment key={index}>
        <div className={cx('meetingsCard')} >
          <div className={cx("meetingParentDiv")}>
            <div className={cx('meetingHeader')}>
              <div className={cx('meetingTitleId')} >{pd.meeting_heading}</div>
              <div className={cx('actionCell')}>
                <Tooltip
                  tooltip={
                    intl.formatMessage({ id: 'Controls.joinMeeting' })
                  }
                >
                  <img className={cx('editIcon')} src={`/icons/joinMeeting.png`} onClick={
                    () => {
                      joinMeetingFromList(pd.Title)
                    }
                  } />
                </Tooltip>
                <Tooltip
                  tooltip={
                    intl.formatMessage({ id: 'Controls.editMeeting' })
                  }
                >
                  <img className={cx('deleteIcon')} src={`/icons/editMeeting.png`} onClick={() => {
                    editMeetingFromList(pd.Title)
                  }} />
                </Tooltip>
                <Tooltip
                  tooltip={
                    intl.formatMessage({ id: 'Controls.deleteMeeting' })
                  }
                >
                  <img className={cx('deleteIcon')} src={`/icons/deleteMeeting.png`} onClick={() => {
                    deleteMeeting(pd.nylas_event_id, pd.meeting_heading)
                  }} />
                </Tooltip>
              </div>
            </div>
            <div className={cx('meetingDate')}>{timer(pd.from_ts)}</div>
          </div>
        </div>
      </React.Fragment>
    )
    setPostData(post_Data)
  }
  return (
    <>
      { login_flag &&
        <div className={cx('upcomingMeetings')}>

          {
            <div className={cx('upcomingMeetings_formWrapper')}>
              <img className={cx('logo')} src={`/${logo_name}_logo.png`} />
              <div className={cx('versionNumber')}>Version: {pkgJSON.version}</div>

              {
                <div style={{ color: "black" }}>
                  {total_meeting > 0 ? postData : !loading ? "No record found!" : ""}
                </div>}
              <div className={cx('login-bottom-link1')}>
                <div>
                  {currentPage.page > 1 &&
                    <div className={cx('prevLink', 'olderLinkBlackColor')} onClick={async () => { currentPage.page = currentPage.page - 1; await handlePageClick() }}>
                      {'<<<'}
                    </div>}
                  {loading &&
                    <div className={cx('upcoming_meeting_spinner')}>
                      {Array.from(Array(12).keys()).map(key => (
                        <div key={key} className={cx('upcoming_meeting_circle', `upcoming_meeting_circle${key + 1}`)} />
                      ))}
                    </div>}
                  {total_meeting > currentPage.page * perPage && <div className={cx('nextLink', 'olderLinkBlackColor')} onClick={async () => { currentPage.page = currentPage.page + 1; await handlePageClick() }}>
                    {'>>>'}
                  </div>}

                  <div className={cx('upcoming_meeting_loginLink', 'upcoming_meeting_loginLinkBlackColor', 'upcoming_meeting_leftSide')} onClick={() => {
                    history.push(routes.JOIN);
                  }}>
                    <FormattedMessage id="Back.link" />
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      }
    </>
  );
}