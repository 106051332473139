/* eslint-disable */
// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import classNames from 'classnames/bind';
import React, { useState } from 'react';
import styles from './AddPeopleToMeeting.css';
import commonob from '../constants/common.json';
import { shortMeetingUrl } from "../common_function"

const cx = classNames.bind(styles);

type Props = {
  title: string;
  name: string;
  onClickCancelButton: () => void;
};

export default function AddPeopleToMeeting(props: Props) {
  const {
    title,
    name,
    onClickCancelButton,
  } = props;

  const [copy, setCopy] = useState({
    title: null,
    link: null,
    titleTO: null,
    linkTO: null
  })


  async function copyCodeToClipboard() {  
    var shortLinkUrl = await shortMeetingUrl(title)
    return shortLinkUrl
  }

  async function copyLink() {
    let shortLink = await copyCodeToClipboard()
    if (shortLink !== "") {
      var input: any = parent.document.createElement("input");

      parent.document.body.appendChild(input);
      input.value = shortLink
      input.select();
      parent.document.execCommand("copy");
      parent.document.body.removeChild(input);

      let tm = copy.linkTO;
      if (tm !== null) {
        clearTimeout(copy.titleTO)
      } else {
        let time = setTimeout(() => {
          setCopy((data) => ({ ...data, link: null, linkTO: null }));
        }, 3000)
        setCopy((data) => ({ ...data, linkTO: time }));
      }
    }
  }

  function copyTitleToClipboard() {
    var input = parent.document.createElement("input");
    parent.document.body.appendChild(input);
    input.value = title
    input.select();
    parent.document.execCommand("copy");
    parent.document.body.removeChild(input);

    let tm = copy.titleTO;
    if (tm !== null) {
      clearTimeout(copy.titleTO)
    } else {
      let time = setTimeout(() => {
        setCopy((data) => ({ ...data, title: null, titleTO: null }));
      }, 3000)
      setCopy((data) => ({ ...data, titleTO: time }));
    }
  }

  const sendMail = async () => {
    let link = await copyCodeToClipboard();
    const mailto: string =
      `mailto:?subject=${name} has invited you to join meeting "${title}"&body=Hello, %0D%0A%0D%0A You are invited by ${name} to join meeting ${title} %0D%0A%0D%0A You can enter into meeting by clicking the following link: %0D%0A ${link} %0D%0A%0D%0A Thanks`;
    window.location.href = mailto;
  }

  return (
    <div className={cx('screenPicker')}>
      <div className={cx('top1')}>
        <h1 className={cx('header')}>
          Add people to meeting
          <div onClick={onClickCancelButton} style={{ cursor: 'pointer', float: 'right' }} >  x </div>
        </h1>

        <div style={{ padding: '0rem 1.5rem' }} >
          Meeting ID X:  {title}  &nbsp;
    {copy.title == "copied" ?
            <div style={{ float: 'left', display: 'contents', transition: 'width 2s' }}>
              <i className="fa fa-check" aria-hidden="true"></i>
            </div>
            :
            <>
              <img
                onClick={() => {
                  setCopy((data) => ({ ...data, title: "copied" }));
                  copyTitleToClipboard()
                }}
                style={{ cursor: 'pointer', transition: 'width 2s' }}
                src="icons/copy-icon.png" />
            </>
          }

        </div>
      </div>
      <div className={cx('middle')} >
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setCopy((data) => ({ ...data, link: "copied" }));
            copyLink()
          }}
          className={cx('copylink')}>
          Copy link meeting
            {copy.link == "copied" && (
            <>
              <i style={{ marginLeft: '10px' }} className="fa fa-check" aria-hidden="true"></i>
            </>
          )}
        </div>

        <div
          style={{ cursor: 'pointer' }}
          onClick={sendMail} className={cx('copylink')}>
          Share via email
        </div>
      </div>
    </div>
  );
}
