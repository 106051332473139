/* eslint-disable */
// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0


import React, { useState } from 'react';
import styles from './RemoteVideo.css';
import classNames from 'classnames/bind';
import { useIntl } from 'react-intl';
import ViewMode from '../enums/ViewMode';
import RosterAttendeeType from '../types/RosterAttendeeType';

const cx = classNames.bind(styles);

export enum Size {
  Small,
  Medium,
  Large
}

type Props = {
  size: Size;
  attId_: string;
  zoomDisplay: any;
  viewMode: ViewMode;
  enabled: boolean;
  fullscreen_: number;
  raisedHand?: boolean;
  boundAttendeeId: string;
  numberOfVisibleIndices: number;
  isContentShareEnabled: boolean;
  rosterAttendee?: RosterAttendeeType;
  videoElementRef: (instance: HTMLVideoElement | null) => void;
};

export default function RemoteVideo(props: Props) {
  const intl = useIntl();
  const {
    attId_,
    viewMode,
    enabled,
    raisedHand,
    zoomDisplay,
    fullscreen_,
    boundAttendeeId,
    size = Size.Large,
    rosterAttendee = {},
    isContentShareEnabled,
    numberOfVisibleIndices,
    videoElementRef,
  } = props;

  var { name, muted } = rosterAttendee;

  var [fullscreen, setZoomScreen] = useState(0);
  var [zoomtype, setZoomType] = useState('');

  function zoomPlus(arg: number, type: string) {
    zoomtype = type;
    setZoomScreen(1);
  }

  function zoomMinus(arg: number, type: string) {
    setZoomType(type);
    setZoomScreen(2);
  }

  return (
    <div
      className={cx('remoteVideo', {
        roomMode: viewMode === ViewMode.Room,
        screenShareMode: viewMode === ViewMode.ScreenShare,
        enabled,
        small: size === Size.Small,
        medium: size === Size.Medium,
        large: size === Size.Large,
        isContentShareEnabled
      })}
    >
      <video muted ref={videoElementRef} className={cx('video', {
        fullscreen: fullscreen_ === 1 && boundAttendeeId === attId_,
        displaynone: fullscreen_ === 1 && boundAttendeeId !== attId_
      })} />

      {name && typeof muted === 'boolean' && (
        <div className={cx('nameplate', 'fullscreen1', {
          marginleft: numberOfVisibleIndices === 1 && zoomtype === '' && fullscreen_ !== 1,
          displaynone: fullscreen_ === 1 && boundAttendeeId !== attId_,
          fullscreenTwo: numberOfVisibleIndices > 1 && fullscreen_ !== 1,
        })} >
          {name && <div className={cx('name')}>{name}</div>}
          {typeof muted === 'boolean' && (
            <>
              <div className={cx('muted')}>
                {muted ? (
                  <i className="fas fa-microphone-slash" />
                ) : (
                  <i className="fas fa-microphone" />
                )}
              </div>

              <div onClick={
                () => {
                  {
                    fullscreen === 0 || fullscreen === 2 ? zoomPlus(1, 'zoom') : '';
                    fullscreen === 1 ? zoomMinus(2, 'zoom') : '';
                  }
                  if (fullscreen === 0 || fullscreen === 2) {
                    zoomDisplay(1, boundAttendeeId);
                  }
                  else {
                    zoomDisplay(2, '');
                  }

                }
              } className={cx('muted')}>

                {boundAttendeeId === attId_ && (
                  (fullscreen === 0 || fullscreen === 2) ? <i className="fas fa-search-plus" /> : <i className="fas fa-search-minus" />
                )
                }
                {boundAttendeeId !== attId_ && (
                  <i className="fas fa-search-plus" />
                )
                }

              </div>


              <div onClick={
                () => {
                  {
                    {
                      fullscreen === 0 || fullscreen === 2 ? zoomPlus(1, 'fs') : '';
                      fullscreen === 1 ? zoomMinus(2, 'fs') : '';
                    }

                    if (fullscreen === 0 || fullscreen === 2)
                      zoomDisplay(1, boundAttendeeId);
                    else
                      zoomDisplay(2, '');
                  }
                }

              } className={cx('muted')}>
                <i className="fas fa-arrows-alt" />
              </div>

            </>
          )}
        </div>
      )}

      {raisedHand && (
        <div className={cx('raisedHand')}>
          <span
            role="img"
            aria-label={intl.formatMessage({
              id: 'RemoteVideo.raiseHandAriaLabel'
            })}
          >
            ✋
        </span>
        </div>
      )}
    </div>
  );

}
