/* eslint-disable  */ 

// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { VideoTileState } from 'amazon-chime-sdk-js';
import classNames from 'classnames/bind';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import getChimeContext from '../context/getChimeContext';
import getRosterContext from '../context/getRosterContext';
import getUIStateContext from '../context/getUIStateContext';
import useRaisedHandAttendees from '../hooks/useRaisedHandAttendees';
import RosterAttendeeType from '../types/RosterAttendeeType';
import styles from './Roster.css';
import ClassMode from '../enums/ClassMode';
import ViewMode from '../enums/ViewMode';
import Tooltip from './Tooltip';

const cx = classNames.bind(styles);

type Props = {
    viewMode: ViewMode;
    publicChannel: string;
} 

export default function Roster(props: Props) {
  const { viewMode, publicChannel } = props;  
  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  const roster = useContext(getRosterContext());
  const [state] = useContext(getUIStateContext());
  const [videoAttendees, setVideoAttendees] = useState(new Set());
  const raisedHandAttendees = useRaisedHandAttendees();
  const intl = useIntl();

  useEffect(() => {
    const tileIds: { [tileId: number]: string } = {};
    // <tileId, attendeeId>
    const realTimeVideoAttendees = new Set();

    const removeTileId = (tileId: number): void => {
      const removedAttendeeId = tileIds[tileId];
      delete tileIds[tileId];
      realTimeVideoAttendees.delete(removedAttendeeId);
      setVideoAttendees(new Set(realTimeVideoAttendees));
    };

    chime?.audioVideo?.addObserver({
      videoTileDidUpdate: (tileState: VideoTileState): void => {
        if (
          !tileState.boundAttendeeId ||
          tileState.isContent ||
          !tileState.tileId
        ) {
          return;
        }

        if (tileState.active) {
          tileIds[tileState.tileId] = tileState.boundAttendeeId;
          realTimeVideoAttendees.add(tileState.boundAttendeeId);
          setVideoAttendees(new Set(realTimeVideoAttendees));
        } else {
          removeTileId(tileState.tileId);
        }
      },
      videoTileWasRemoved: (tileId: number): void => {
        removeTileId(tileId);
      }
    });
  }, []);
  const myAttendeeId = chime?.configuration?.credentials?.attendeeId;
  let attendeeIds;
  if (chime?.meetingSession && roster) {
    attendeeIds = Object.keys(roster).filter( (aId) => aId !== myAttendeeId );
  }

  return (
    <div className={cx('roster')}>
      <div className={cx('rosterscroll')} >
      {attendeeIds &&
        attendeeIds.map((attendeeId: string) => {
          const rosterAttendee: RosterAttendeeType = roster[attendeeId];
          return (
            <div 
            style={{ display :  rosterAttendee.name ==  '«Meeting Recorder»' ? 'none' : ''}}
            key={attendeeId} className={cx('attendees')}>
                
              <div style={{ 'marginRight' : '1rem'}} className={cx('video_new')}>
                <i className={cx('fas', {
                  'fa-video' : videoAttendees.has(attendeeId),
                  'fa-video-slash' : !videoAttendees.has(attendeeId)
                })} />
              </div>
              {typeof rosterAttendee.muted === 'boolean' && (
                <div style={{ 'marginRight' : '1rem'}} className={cx('muted_new')}>
                  {rosterAttendee.muted ? (
                    <i className="fas fa-microphone-slash" />
                  ) : (
                    <i className="fas fa-microphone" />
                  )}
                </div>
              )}

              <div className={cx('names')}>{rosterAttendee.name}</div>
              {raisedHandAttendees.has(attendeeId) && (
                <div className={cx('raisedHand')}>
                  <span
                    role="img"
                    aria-label={intl.formatMessage(
                      {
                        id: 'Roster.raiseHandAriaLabel'
                      },
                      {
                        name: rosterAttendee.name
                      }
                    )}
                  >
                    ✋
                  </span>
                </div>
              )}


              {state.classMode === ClassMode.Teacher && viewMode === ViewMode.Room && (
                 <> 
                {typeof rosterAttendee.muted === 'boolean' && (
                  <Tooltip  tooltip={  !rosterAttendee.muted ? "Mute attendee" :  "Unmute attendee" } >
                    <div style={{cursor: 'pointer', 'marginRight' : '1rem'}} onClick={ ()=> {
                      let msg1 = {
                            name: chime?.roster[attendeeId].name,
                            payload: {
                              attendeeId: attendeeId, focus: !rosterAttendee.muted, message: "mute-attendee"
                            },
                            timestampMs: Date.now(),
                            type: "mute-attendee"
                        }
                        chime?.pubnub?.publish({ channel : publicChannel, message: msg1})
                    
                    }} className={cx('muted_new')}>
                      {rosterAttendee.muted ? (
                        <i style={{'color': '#585859'}} className="fas fa-microphone-slash" />
                      ) : (
                        <i style={{'color': '#585859'}} className="fas fa-microphone" />
                      )}
                    </div>
                  </Tooltip>
                )}

                <Tooltip  tooltip={ 'Remove attendee'} >
                  <div style={{cursor: 'pointer'}} onClick={ () => {
                      if(confirm(`Are you sure to remove ${rosterAttendee.name} from meeting ?`)){
                        let msg1 = {
                            name: chime?.roster[attendeeId].name,
                            payload: {
                              attendeeId: attendeeId, message: "remove-attendee"
                            },
                            timestampMs: Date.now(),
                            type: "remove-attendee"
                        }
                        chime?.pubnub?.publish({ channel : publicChannel, message: msg1})

                      }
                  }}
                  className={cx('video_new')}>
                      <i style={{'color': '#585859'}} className={cx('fas fa-user-times')} />
                  </div>
                </Tooltip>
                </>
              )}
            </div>
          );
        })}
        </div>
    </div>
  );
}
