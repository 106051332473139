// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/* eslint-disable  */ 

import React, { ReactNode, useState, useReducer } from 'react';

import getGlobalVarContext from '../context/getGlobalVarContext';

type Props = {
  children: ReactNode;
};

export default function GlobalVarProvider(props: Props) {
  
  const { children } = props;
  const GlobalVarContext = getGlobalVarContext();
  const [globalVar, setGlobalVar] = useState({
  		activeAttendee: ""
  });
    return (
    <GlobalVarContext.Provider value={{ globalVar, setGlobalVar }}>
    	{children}
    </GlobalVarContext.Provider>
  );

}
