// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/* eslint-disable  */

import classNames from 'classnames/bind';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import routes from '../constants/routes.json';
import getUIStateContext from '../context/getUIStateContext';
import FormValidate from './FormValidate';
import styles from './CreateOrJoin.css';
import commonob from '../constants/common.json'

const cx = classNames.bind(styles);
const LR_API_KEY = commonob.loginradius.api_key;
const LR_API_SECRET = commonob.loginradius.api_secret;

export default function Signup() {
  const [, dispatch] = useContext(getUIStateContext());
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cpassword, setCpassword] = useState('');
  const [error, setError] = React.useState({});

  const history = useHistory();
  const intl = useIntl();

  useEffect(() => {
    localStorage.clear();
    dispatch({
      type: 'SET_CLASS_MODE',
      payload: {
        classMode: null
      }
    });
  }, []);


  function callRegisterAPI(body, sott) {
    let error: any = {}
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', "X-LoginRadius-Sott": sott },
      body: body
    };

    fetch(commonob.loginradius.url + "/auth/register?apikey=" + LR_API_KEY + "&verificationurl=&loginurl=&emailtemplate=&g-recaptcha-response=", requestOptions)
      .then(async (response) => {
        const data = await response.json();
        // check for error response
        if (!response.ok) {
          error['error'] = data.Description
        } else {
          error['success'] = 'You have successfully signed up. Please check your inbox to activate your account.'
        }
        setError(error)
      })
      .catch(console.log)
  }

  function getSott(resolve, reject) {
    let error: any = {}
    let requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };

    fetch(commonob.loginradius.url + "/manage/account/sott?apisecret=" + LR_API_SECRET + "&apikey=" + LR_API_KEY + "&timedifference=100", requestOptions)
      //.then(res => res.json())
      .then(async (response) => {
        let data = await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          error['error'] = data.Description
        } else {
          resolve(data["Sott"]);
        }
        setError(error)
      })
      .catch(console.log)
  }

  function handleSubmit(event: any) {
    event.preventDefault();
    setError({})
    let error: any = {}
    if (!FormValidate.isEmail(email)) {
      error['email'] = `Invalid ${intl.formatMessage({ id: 'Signup.email' })}`
    }
    if (FormValidate.isEmpty(password)) {
      error['password'] = `Invalid ${intl.formatMessage({ id: 'Signup.password' })}`
    }
    if (FormValidate.isEmpty(cpassword)) {
      error['cpassword'] = `Invalid ${intl.formatMessage({ id: 'Signup.cpassword' })}`
    }
    if (!FormValidate.isEmpty(email, password, cpassword) && password != cpassword) {
      error['cpassword'] = `Mismatch ${intl.formatMessage({ id: 'Signup.cpassword' })}`
    }

    if (FormValidate.isEmpty(email, password, cpassword) || password != cpassword) {
      setError(error)
    } else {
      setError({})
      alert("Register Form Submitted")
      let body = JSON.stringify({ "Email": [{ "Type": "Primary", "Value": email }], "Password": password })

      return new Promise(async (resolve, reject) => {
        let sott = await getSott(resolve, reject);
      }).then((sott) => {
        callRegisterAPI(body, sott)
      });
    }
  }

  return (
    <div className={cx('createOrJoin')}>
      <div className={cx('formWrapper')}>
        <img className={cx('logo')} src="public/logo.png" />
        <h1 className={cx('title')}>
          <FormattedMessage id="Signup.title" />
        </h1>

        <div className={cx('form')}>
          <form onSubmit={handleSubmit}>
            <input
              className={cx('titleInput')}
              onChange={event => {
                setEmail(event.target.value);
              }}
              placeholder={intl.formatMessage({
                id: 'Signup.email'
              })}
            />
            {error['email'] ? <div className={styles.errorMessage}>{error['email']}</div> : null}
            <input
              className={cx('nameInput')}
              type="password"
              onChange={event => {
                setPassword(event.target.value);
              }}
              placeholder={intl.formatMessage({
                id: 'Signup.password'
              })}
            />
            {error['password'] ? <div className={styles.errorMessage}>{error['password']}</div> : null}
            <input
              className={cx('nameInput')}
              type="password"
              onChange={event => {
                setCpassword(event.target.value);
              }}
              placeholder={intl.formatMessage({
                id: 'Signup.cpassword'
              })}
            />
            {error['cpassword'] ? <div className={styles.errorMessage}>{error['cpassword']}</div> : null}

            <button className={cx('button')} type="submit">
              <FormattedMessage id="Signup.registerbutton" />
            </button>

            {error['error'] ? <div className={styles.errorMessage}>{error['error']}</div> : ""}
            {error['success'] ? <div className={styles.successMessage}>{error['success']}</div> : ""}
          </form>
        </div>

        <div className={cx('login-bottom-link')}>
          <div>
            <Link style={{ float: "left" }} className={cx('loginLink')} to={routes.LOGIN}>
              <FormattedMessage id="UserLogin.title" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
