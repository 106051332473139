/* eslint-disable  */

// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { VideoTileState } from 'amazon-chime-sdk-js';
import classNames from 'classnames/bind';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import getChimeContext from '../context/getChimeContext';
import styles from './LocalVideo.css';

const cx = classNames.bind(styles);

type Props = {
  tileView: boolean;
  videoLength: any;
  onShowLocalVidoe: (bl: boolean) => Boolean;
};

export default function LocalVideo(props: Props) {

  const {
    tileView,
    videoLength,
    onShowLocalVidoe,
  } = props;

  const [enabled, setEnabled] = useState(false);

  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  const videoElement = useRef(null);

  let userName = chime?.name;
  const initials = userName?.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g)?.join('');

  useEffect(() => {

    chime?.audioVideo?.addObserver({
      videoTileDidUpdate: (tileState: VideoTileState): void => {
        if (
          !tileState.boundAttendeeId ||
          !tileState.localTile ||
          !tileState.tileId ||
          !videoElement.current
        ) {
          return;
        }

        chime?.audioVideo?.bindVideoElement(
          tileState.tileId,
          (videoElement.current as unknown) as HTMLVideoElement
        );

        onShowLocalVidoe(tileState.active);
        setEnabled(tileState.active);
      }
    });
  }, []);

  return (
    <div
      className={cx({
        localVideo: !tileView,
        videoTileview: tileView
      })}
    >
      <video
        style={{
          height:
            (tileView) &&
            '100%'
        }}
        muted ref={videoElement} className={cx('video')} />
      {tileView && !enabled && <div
        style={{
          top:
            "41%", padding: '0%',
          fontSize: (videoLength <= 1) ? "65px" : "30px"
        }}
        className={cx('attendeeName')}>
        {initials}
      </div>}
      {!tileView && !enabled && <div
        className={cx('attendeeName')}>
        {initials}
      </div>}
      <div className={cx({
        namemain: !tileView,
        namemain1: tileView
      })}>
        <div className={cx({
          host: !tileView,
          host1: tileView
        })}>Me</div>
        <div className={cx({
          name: !tileView,
          name1: tileView
        })}>&nbsp;&nbsp;{userName}</div>
      </div>
    </div>
  );
}
