/* eslint-disable  */

// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { VideoTileState, DefaultActiveSpeakerPolicy } from 'amazon-chime-sdk-js';
import classNames from 'classnames/bind';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import getChimeContext from '../context/getChimeContext';
import getRosterContext from '../context/getRosterContext';
import ViewMode from '../enums/ViewMode';
import useRaisedHandAttendees from '../hooks/useRaisedHandAttendees';
import RosterAttendeeType from '../types/RosterAttendeeType';
import styles from './RemoteVideoGroup.css';
import { Size } from './RemoteVideo';
import RosterLayout from './RosterLayout';
import RosterLayout1 from './RosterLayout1';
import LocalVideo from './LocalVideo';
import Tooltip from './Tooltip';

const cx = classNames.bind(styles);
const MAX_REMOTE_VIDEOS = 16;

type Props = {
  viewMode: ViewMode;
  isSmallScreen: boolean;
  isRightBarEnabled: boolean;
  isContentShareEnabled: boolean;
  shareScreenSmall: (ss: boolean) => void;
  setActiveAttendeeForChatParent: (id: string) => void;
};

let tm: any = 0;
let r_attd = '';
let tileFullView = false;

export default function RemoteVideoGroup(props: Props) {
  const {
    viewMode,
    isSmallScreen,
    isContentShareEnabled,
    isRightBarEnabled,
    shareScreenSmall,
    setActiveAttendeeForChatParent
  } = props;

  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  const roster = useContext(getRosterContext());
  const [visibleIndices, setVisibleIndices] = useState<{
    [index: string]: { boundAttendeeId: string };
  }>({});

  const [minmax, setMinMax] = useState(true);
  const [tileView, setTileView] = useState(false);
  const [elemHeight, setElemHeight] = useState(0);
  const [activeSpeaker, setActiveSpeaker] = useState("");
  const [attendeeIdFullScreen, setAttendeeId] = useState('');
  const currentUser = chime?.configuration.credentials.attendeeId;
  const [localVideoView, setLocalVideoView] = useState(false);

  const raisedHandAttendees = useRaisedHandAttendees();
  const videoElements: HTMLVideoElement[] = [];
  const tiles: { [index: number]: number } = {};
  let attdLength: any = Object.keys(roster);
  const intl = useIntl();
  const meetingRecorderName = "«Meeting Recorder»";

  useEffect(() => {
    // if content is shareble --sanjay balai
    if (localStorage.getItem("contentshare") == 'true') {
      localStorage.setItem("contentshare", 'none');
      setAttendeeId("")
      setMinMax(false);
      setTileView(false)
    } else if (localStorage.getItem("contentshare") == 'false') {
      localStorage.setItem("contentshare", 'none');
      setMinMax(true);
    }
    // if content is shareble + contentshare screen is not small --sanjay balai
    if (isSmallScreen == false && isContentShareEnabled == true) {
      setAttendeeId("")
      setTileView(false)
    }
    // if no one can join meeting set tileview disable --sanjay balai
    if (attendeeIds.length == 0) {
      setTileView(false)
    }

    // this is for tileview video hieght get from class --sanjay balai
    let window_height = document.querySelector(".tileView");
    if (window_height) {
      setElemHeight(window_height.clientHeight - 50)
    }
    if (chime?.roster[attendeeIdFullScreen]?.muted == true && !tileFullView) {
      setAttendeeId("")
      setActiveSpeaker("")
    }
  })

  const acquireVideoIndex = (tileId: number): number => {
    for (let index = 0; index < MAX_REMOTE_VIDEOS; index += 1) {
      if (tiles[index] === tileId) {
        return index;
      }
    }
    for (let index = 0; index < MAX_REMOTE_VIDEOS; index += 1) {
      if (!(index in tiles)) {
        tiles[index] = tileId;
        return index;
      }
    }
    throw new Error('no tiles are available');
  };

  const releaseVideoIndex = (tileId: number): number => {
    for (let index = 0; index < MAX_REMOTE_VIDEOS; index += 1) {
      if (tiles[index] === tileId) {
        delete tiles[index];
        return index;
      }
    }
    return -1;
  };

  const numberOfVisibleIndices = Object.keys(visibleIndices).reduce<number>(
    (result: number, key: string) => result + (visibleIndices[key] ? 1 : 0),
    0
  );

  const [videoAttendees, setVideoAttendees] = useState(new Set());

  const activeSpeakerCallback = (attendeeIds) => {
    //remove selfAttendeeId when Speaker active   --sanjay balai
    if (attendeeIds.length) {
      let selfAttendeeId = currentUser;
      function removeSelfAttendeeId(arr, value) {
        return arr.filter(function (id) {
          return id != value;
        });
      }
      let activeAttendee = removeSelfAttendeeId(attendeeIds, selfAttendeeId)

      if (activeAttendee.length) {

        // checks whether attendee is UnMute then only set as Active Speaker --sanjay balai
        if (chime?.roster[activeAttendee[0]]?.muted == false) {
          if (tm === 0) {
            tm = setTimeout(() => {
              if (attendeeIdFullScreen === activeAttendee[0]) {
                clearTimeout(tm);
              } else {
                if (localStorage.getItem('shareScreen') == 'false' && !tileFullView) {
                  setAttendeeId(activeAttendee[0])
                  setActiveSpeaker(activeAttendee[0])
                  tm = 0
                } else {
                  setActiveSpeaker(activeAttendee[0])
                  tm = 0
                }
              }
            }, 2250)
          }
        }

      }
    };
  }

  // find the number of attendee join --sanjay balai
  let activeAttendee: any;
  if (chime?.meetingSession && roster) {

    activeAttendee = Object.keys(roster);

    activeAttendee.forEach(function (item: string, i: number) {
      if (item === currentUser) {
        activeAttendee.splice(i, 1);
        activeAttendee.unshift(item);
      }
      if(chime?.roster[item].name == ''){
        activeAttendee.splice(i, 1);
      }
    });

  }

  // remove self attendee for roster layout --sanjay balai
  let selfAttendeeId = currentUser;
  function removeSelfAttendeeId(arr, value) {

    return arr.filter(function (id) {
      return id != value;
    });
  }
  let attendeeIds = removeSelfAttendeeId(activeAttendee, selfAttendeeId)


  const getSize = (): Size => {
    if (numberOfVisibleIndices >= 10) {
      return Size.Small;
    }
    if (numberOfVisibleIndices >= 5) {
      return Size.Medium;
    }
    return Size.Large;
  };

  useEffect(() => {
    // active speaker listner called --sanjay balai
    chime?.audioVideo?.subscribeToActiveSpeakerDetector(
      new DefaultActiveSpeakerPolicy,
      activeSpeakerCallback
    );

    // remove tile id when attendee leave --sanjay balai
    const tileIds: { [tileId: number]: string } = {};
    const realTimeVideoAttendees = new Set();
    const removeTileId = (tileId: number): void => {
      const removedAttendeeId = tileIds[tileId];
      delete tileIds[tileId];
      realTimeVideoAttendees.delete(removedAttendeeId);
      setVideoAttendees(new Set(realTimeVideoAttendees));
      // when attendee video on fullscreen and suddenly attendee leave --sanjay balai
      if ((localStorage.getItem('shareScreen') == 'true' || tileFullView) && removedAttendeeId == r_attd) {
        setAttendeeId('')
        shareScreenSmall(false);
        setTileView(false)
        tileFullView = false;
      }
    };

    chime?.audioVideo?.addObserver({
      videoTileDidUpdate: (tileState: VideoTileState): void => {

        if (
          !tileState.boundAttendeeId ||
          tileState.localTile ||
          tileState.isContent ||
          !tileState.tileId
        ) {
          return;
        }

        const index = acquireVideoIndex(tileState.tileId);

        chime?.audioVideo?.bindVideoElement(
          tileState.tileId,
          videoElements[index],
        );
        setVisibleIndices(previousVisibleIndices => ({
          ...previousVisibleIndices,
          [index]: {
            boundAttendeeId: tileState.boundAttendeeId
          }
        }));

        // code for audio/video enable or not start
        if (tileState.active) {
          tileIds[tileState.tileId] = tileState.boundAttendeeId;
          realTimeVideoAttendees.add(tileState.boundAttendeeId);
          setVideoAttendees(new Set(realTimeVideoAttendees));
        } else {
          removeTileId(tileState.tileId);
        }
        // code for audio/video enable or not end
      },
      videoTileWasRemoved: (tileId: number): void => {
        const index = releaseVideoIndex(tileId);
        setVisibleIndices(previousVisibleIndices => ({
          ...previousVisibleIndices,
          [index]: null
        }));
        // code for audio/video enable or not start
        removeTileId(tileId);
        // code for audio/video enable or not end

      }
    });

  }, []);

  // get height and width of tileview from class for responsive view --sanjay balai
  function reorganize() {
    let maxCols = 7;
    let numPeople = attdLength.length;

    let cols = 1;
    for (cols; cols <= maxCols; cols++) {
      if (numPeople <= (cols * cols)) { break; }
    }
    let row = Math.ceil(numPeople / cols)
    let Twidth = `${100 / cols}%`;
    let Theight = `${elemHeight / row}px`
    return { width: Twidth, height: Theight };
  }


  return (
    <>
      <div
        style={tileView ? { background: 'none' } : {}}
        className={cx(
          'remoteVideoGroup',
          {
            roomMode: viewMode === ViewMode.Room && !tileView,
            screenShareMode: viewMode === ViewMode.ScreenShare,
            isContentShareEnabled,
            tileView: tileView && attendeeIds.length != 0,
            tileViewRightBarEnabled: tileView && isRightBarEnabled && attendeeIds.length != 0,
          }
        )}
      >
        {numberOfVisibleIndices === 0 && !isContentShareEnabled && (
          <div className={cx('instruction')}>
          </div>
        )}

        <div className={cx('rightmain')}>
          <div className={cx('rightmainleft')}>
            <Tooltip tooltip={intl.formatMessage({ id: 'TileView.minimize' })
            }>
              <div
                style={tileView ? { display: 'none' } : { width: '33%', textAlign: 'left', float: 'left' }}
                onClick={
                  () => {
                    setMinMax(false)
                    setTileView(false)
                    tileFullView = false;
                    shareScreenSmall(false)
                  }
                } className={cx('minimize')}>
                <i style={{ margin: '0px 0px 0px 4px', position: 'relative', top: '1px' }} className="fas fa-window-minimize"></i>
              </div>
            </Tooltip>


            <div
              style={tileView ? { width: '99%', textAlign: 'right' } : { width: '33%', textAlign: 'center', float: 'left' }}
              onClick={
                () => {
                  setMinMax(true)
                  setTileView(false)
                  tileFullView = false;
                  shareScreenSmall(false)
                }
              } className={cx('maximize')}>
              {!tileView ? <Tooltip tooltip={intl.formatMessage({ id: 'TileView.maximize' })
              }>
                <i style={{ position: 'relative', top: '1px' }} className="fas fa-window-maximize"></i>
              </Tooltip>
                :
                <Tooltip tooltip={intl.formatMessage({ id: 'TileView.listview' })
                }>
                  <i className="fa fa-list" aria-hidden="true"></i>
                </Tooltip>
              }
            </div>


            <div
              style={tileView ? { display: 'none' } : { width: '33%', textAlign: 'right', float: 'left' }}
              onClick={
                () => {
                  if (attendeeIds.length != 0) {
                    setTileView(true)
                    shareScreenSmall(true);
                    setMinMax(true)
                    tileFullView = false;
                    localStorage.setItem('fullscreen', 'false')
                  }
                }
              } className={cx('tileViewIcon')}>
              {!tileView &&
                <Tooltip tooltip={intl.formatMessage({ id: 'TileView.gridview' })}>
                  <i style={{ margin: '0px 4px 0 0', position: 'relative', top: '1px' }} className="fa fa-th" aria-hidden="true"></i>
                </Tooltip>
              }
            </div>
          </div>
        </div>

        <div
          style={{ "display": minmax ? "block" : "none" }}
          className={cx({
            videostart: !tileView,
            videoTileView: tileView && attendeeIds.length != 0,
          })}>
          <div
            className={cx(
              `attendeeTileViews-${attendeeIds.length}`,
              {
                attendeeTileView: tileView,
              })}>
            <div
              style={{ width: tileView && reorganize().width, height: tileView && reorganize().height }}
              className={cx({
                videoOff: localVideoView === true,
              })}>
              <LocalVideo
                onShowLocalVidoe={(bl: boolean): Boolean => {
                  setLocalVideoView(bl);
                  return bl;
                }}
                tileView={tileView}
                videoLength={attendeeIds.length}
              />
            </div>


            {Array.from(Array(MAX_REMOTE_VIDEOS).keys()).map((key, index) => {
              const visibleIndex1 = visibleIndices[index];
              let rosterAttendee1: RosterAttendeeType = {};
              let raisedHand = false;
              let attId1 = '';

              if (visibleIndex1 && roster) {
                rosterAttendee1 = roster[visibleIndex1.boundAttendeeId];
                attId1 = visibleIndex1.boundAttendeeId;
                if (raisedHandAttendees) {
                  raisedHand = raisedHandAttendees.has(visibleIndex1.boundAttendeeId);
                }
              }
              let rosterLength = attendeeIds.length;
              return (

                <div
                  key={key}
                  style={{ display: visibleIndex1 ? "block" : "none", width: tileView && reorganize().width, height: tileView && reorganize().height }}
                  className={cx(
                    {
                      videocenter: attendeeIdFullScreen != '' && attId1 == attendeeIdFullScreen && !tileView,
                    }
                  )}
                >
                  <RosterLayout
                    key={index}
                    videoLength={rosterLength}
                    viewMode={viewMode}
                    tileView={tileView}
                    enabled={!!visibleIndex1}
                    videoElementRef={useCallback((element: HTMLVideoElement | null) => {
                      if (element) {
                        videoElements[index] = element;
                      }
                    }, [])}
                    size={getSize()}
                    rosterAttendee={rosterAttendee1}
                    raisedHand={raisedHand}
                    isContentShareEnabled={isContentShareEnabled}
                    isRightBarEnabled={isRightBarEnabled}
                    boundAttendeeId={attId1}
                    attId_={attendeeIdFullScreen}
                    rendervideotoparent={
                      (baid) => {
                        setAttendeeId(baid)
                        r_attd = baid;
                        shareScreenSmall(true);
                        setTileView(false)
                        tileFullView = true;
                      }
                    }
                    resetFullscreen={
                      () => {
                        setAttendeeId("")
                        shareScreenSmall(false);
                        tileFullView = false;
                      }
                    }
                    isSmallScreen={isSmallScreen}
                    activeSpeaker={attId1 == activeSpeaker}
                    v_height={reorganize().height}
                    volume={chime?.roster[attendeeIdFullScreen]?.volume}
                    setActiveAttendeeForChat={(id: string) => { setActiveAttendeeForChatParent(id) }}
                  />
                </div>
              );
            })}

            {attendeeIds.map((key: string, index: number) => {
              let rosterAttendee: RosterAttendeeType = {};

              if (roster) {
                rosterAttendee = roster[key];
              }
              let rosterLength = attendeeIds.length;

              if(!videoAttendees.has(key) || key != currentUser || chime?.roster[key].name != '' || chime?.roster[key].name != meetingRecorderName){
                return (
                    <div
                      style={{ 
                        display: !videoAttendees.has(key) && rosterAttendee.name != '«Meeting Recorder»' ? 'block' : 'none', width: tileView && reorganize().width, height: tileView && reorganize().height }}
                      key={key}
                      className={cx(
                        {
                          videocenter1: key == attendeeIdFullScreen && videoAttendees.has(key) === false && !tileView,
                          isRightbarVideo: isRightBarEnabled && key == attendeeIdFullScreen && videoAttendees.has(key) === false && !tileView,
                        }
                      )}>
                      {
                        <RosterLayout1
                          tileView={tileView}
                          videoLength={rosterLength}
                          isAttendeeEnable={key == attendeeIdFullScreen}
                          isVideoEnable={videoAttendees.has(key)}
                          size={getSize()}
                          boundAttendeeId={key}
                          rosterAttendee={rosterAttendee}
                          viewMode={viewMode}
                          isRightBarEnabled={isRightBarEnabled}
                          isContentShareEnabled={isContentShareEnabled}
                          rendervideotoparent1={
                            (baid) => {
                              rosterAttendee = baid;
                            }
                          }
                          resetFullscreen1={
                            () => {
                              setAttendeeId("")
                            }
                          }
                          isSmallScreen={isSmallScreen}
                          activeSpeaker={key == activeSpeaker}
                          v_height={reorganize().height}
                          volume={chime?.roster[attendeeIdFullScreen]?.volume}
                          setActiveAttendeeForChat={(id: string) => { setActiveAttendeeForChatParent(id) }}
                        />
                      }
                    </div>
              );
              }else{
                  return null
              }

            })}
          </div>
        </div>
      </div>
    </>
  );
}
