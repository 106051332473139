// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/* eslint-disable  */


import classNames from 'classnames/bind';
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import ViewMode from '../enums/ViewMode';
import RosterAttendeeType from '../types/RosterAttendeeType';
import styles from './RosterLayout.css';

const cx = classNames.bind(styles);

export enum Size {
  Small,
  Medium,
  Large
}

type Props = {

  size: Size;
  volume: any;
  v_height: any;
  attId_: string;
  videoLength: any;
  viewMode: ViewMode;
  enabled: boolean;
  tileView: boolean;
  isRightBarEnabled: boolean;
  boundAttendeeId: string;
  isSmallScreen: boolean;
  activeSpeaker: boolean;
  raisedHand?: boolean;
  isContentShareEnabled: boolean;
  rosterAttendee?: RosterAttendeeType;
  resetFullscreen: () => void;
  rendervideotoparent: (b: string) => void;
  setActiveAttendeeForChat: (id: string) => void;
  videoElementRef: (instance: HTMLVideoElement | null) => void;
};

export default function RosterLayout(props: Props) {
  const intl = useIntl();
  const {
    attId_,
    volume,
    viewMode,
    enabled,
    tileView,
    isSmallScreen,
    activeSpeaker,
    videoLength,
    size = Size.Large,
    rosterAttendee = {},
    raisedHand,
    isContentShareEnabled,
    isRightBarEnabled,
    boundAttendeeId,
    resetFullscreen,
    videoElementRef,
    rendervideotoparent,
  } = props;

  var [fullscreen, setFullScreen] = useState(false);

  useEffect(() => {
    setFullScreen((localStorage.getItem('fullscreen') == 'true') == true ? true : false)
  })

  return (
    <div
      className={cx('attendee', 'remoteVideo', {
        screenShareMode: viewMode === ViewMode.ScreenShare,
        enabled,
        large: size === Size.Large && !tileView,
        isContentShareEnabled,
        largevideo: attId_ == boundAttendeeId && !tileView,
        isSpeakerActive: (activeSpeaker && isContentShareEnabled && attId_ != boundAttendeeId) || (activeSpeaker && tileView) || (activeSpeaker && !tileView && attId_ != boundAttendeeId),
      })}
    >
      {/* ATTENDEE'S VIDEO FOR ALL VIEW */}
      <video
        muted
        style={{
          height:
            (tileView) &&
            '100%'
        }}
        ref={videoElementRef}
        className={cx({
          position: boundAttendeeId != '' && attId_ != boundAttendeeId && !isContentShareEnabled && !tileView,
          fullscreen: localStorage.getItem('fullscreen') == 'true' && attId_ == boundAttendeeId,
          fullscreen1: isRightBarEnabled && !fullscreen && attId_ == boundAttendeeId && !tileView
        }, 'video')}
        onClick={
          () => {
            rendervideotoparent(boundAttendeeId)
          }
        }
      />

      {/* ATTENDEE'S VIDEO FOR LEFT SIDE SECTION WHEN ATTENDEE MUTE */}

      {(attId_ != boundAttendeeId && !isContentShareEnabled) && !tileView && (
        <div className={cx('participantStatus')} style={{ margin: "108px 0px 0px 5px", width: "95%" }}>
          <div className={cx('nameStatus')}>
            <div className={cx('name')}>
              {rosterAttendee.name}
            </div>
            {
              typeof rosterAttendee.muted === 'boolean' && (
                <div className={cx('muted', 'manageheight')}>
                  {rosterAttendee.muted ? (
                    <i className="fas fa-microphone-slash" />
                  ) : (
                    <i className="fas fa-microphone marginlefticon" />
                  )}
                </div>
              )
            }
          </div>
        </div>
      )}

      {/* ATTENDEE'S VIDEO FOR TILEVIEW  SECTION */}

      {tileView && (
        <div className={cx('participantStatus')}
          style={{ bottom: videoLength <= 6 ? '15px' : '5px' }}
        >
          <div
            className={cx('nameStatus')}
            style={{ height: videoLength <= 6 && "31px" }}
          >
            <div className={cx('name')}
              style={{ fontSize: videoLength <= 6 && '20px', marginTop: videoLength <= 6 && '3px' }}>
              {rosterAttendee.name}
            </div>

            {
              typeof rosterAttendee.muted === 'boolean' && (
                <div
                  className={cx('muted', 'manageheight')}
                  style={{ fontSize: videoLength <= 6 && "20px" }}
                >
                  {rosterAttendee.muted ? (
                    <i className="fas fa-microphone-slash" />
                  ) : (
                    <i className="fas fa-microphone marginlefticon" />
                  )}
                </div>
              )
            }
          </div>
        </div>
      )}

      {/* ATTENDEE'S VIDEO WHEN ATTENDEES ACTIVE AND SCREEN SHARED */}
      {
        isContentShareEnabled && !isSmallScreen && !tileView && (
          <div
            className={cx('participantStatus')}>
            <div className={cx('nameStatus')}>
              <div className={cx('name')}>
                {rosterAttendee.name}
              </div>
              {
                typeof rosterAttendee.muted === 'boolean' && (
                  <div className={cx('muted', 'manageheight', {
                    marginlefticon: !rosterAttendee.muted
                  })}>
                    {rosterAttendee.muted ? (
                      <i style={{
                        "margin": "-1px 0px 0px 3px"
                      }} className="fas fa-microphone-slash" />
                    ) : (
                      <i style={{
                        "margin": "-1px 0px 0px 7px"
                      }}
                        className="fas fa-microphone marginlefticon" />
                    )}
                  </div>
                )
              }
            </div>
          </div>
        )
      }

      {/* ATTENDEE'S VIDEO WHEN ATTENDEES NOT ACTIVE AND SCREEN SHARED */}

      {isContentShareEnabled && isSmallScreen && attId_ != boundAttendeeId && !tileView && (
        <div
          // style={{ position: 'absolute', left: '4px', top: '8px' }}
          className={cx('participantStatus')}>
          <div className={cx('nameStatus')}>
            <div className={cx('name')}>
              {rosterAttendee.name}
            </div>
            {
              typeof rosterAttendee.muted === 'boolean' && (
                <div className={cx('muted', 'manageheight')}>
                  {rosterAttendee.muted ? (
                    <i style={{
                      "margin": "-1px 0px 0px 3px"
                    }} className="fas fa-microphone-slash" />
                  ) : (
                    <i style={{
                      "margin": "-1px 0px 0px 7px"
                    }}
                      className="fas fa-microphone marginlefticon" />
                  )}
                </div>
              )
            }
          </div>
        </div>
      )
      }

      {/* ATTENDEE'S VIDEO FOR FULL SCREEN WHEN ATTENDEE SPEAKING */}

      {attId_ == boundAttendeeId && !tileView && (
        <div className={cx('participantStatus', {
          participantStatusNew: attId_ == boundAttendeeId,
          participantStatusWidht: isRightBarEnabled && !fullscreen && attId_ == boundAttendeeId,
          participantStatusWithRightsideEnable: ((attId_ == boundAttendeeId && isRightBarEnabled))
        })}>
          <div className={cx('nameStatus')}>

            {/* ATTENDEE'S VOLUME INDICATOR */}

            <div style={{ height: '26px', overflow: 'hidden' }} className={cx('firstdiv')}>
              {
                <div style={{ marginTop: '7px', float: 'left' }}>
                  <div style={{ height: '6px', bottom: '-6px', position: 'relative', background: volume >= 20 ? '#0cca0c' : '#8a8d8a' }} className={cx('volumecontrol')}>&nbsp;</div>
                  <div style={{ height: '8px', bottom: '-4px', position: 'relative', background: volume >= 40 ? '#0cca0c' : '#8a8d8a' }} className={cx('volumecontrol')}>&nbsp;</div>
                  <div style={{ height: '10px', bottom: '-2px', position: 'relative', background: volume >= 60 ? '#0cca0c' : '#8a8d8a' }} className={cx('volumecontrol')}>&nbsp;</div>
                  <div style={{ height: '12px', bottom: '0px', position: 'relative', background: volume >= 80 ? '#0cca0c' : '#8a8d8a' }} className={cx('volumecontrol')}>&nbsp;</div>
                  <div style={{ height: '14px', bottom: '2px', position: 'relative', background: volume >= 100 ? '#0cca0c' : '#8a8d8a' }} className={cx('volumecontrol')}>&nbsp;</div>
                  {rosterAttendee.muted && (
                    <div className={cx('volumecontrol', 'mutemic')}>&nbsp;</div>
                  )}
                </div>
              }
              <span>
                <strong>{rosterAttendee.name}</strong>
              </span>
            </div>


            <div onClick={() => {
              localStorage.setItem('fullscreen', (!fullscreen).toString());
              setFullScreen(!fullscreen);
              rendervideotoparent(boundAttendeeId);
            }}
              className={cx('firstdiv')}>
              {localStorage.getItem('fullscreen') == 'true' ? <i className="fas fa-search-minus" /> : <i className="fas fa-search-plus" />
              }

              <span>{intl.formatMessage({ id: 'RosterLayout.ZoomInZoomOut' })}</span>
            </div>

            <div onClick={() => {
              resetFullscreen()
            }}
              className={cx('firstdiv')}>
              <i className="fas fa-times-circle" />
              <span>{intl.formatMessage({ id: 'RosterLayout.ExitZoom' })}</span>
            </div>
          </div>
        </div>

      )}

      {raisedHand && !tileView && (
        <div className={cx('raisedHand')}>
          <span
            role="img"
            aria-label={intl.formatMessage({
              id: 'RemoteVideo.raiseHandAriaLabel'
            })}
          >
            ✋
          </span>
        </div>
      )}
    </div>
  );
}
