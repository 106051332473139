/* eslint-disable */  
// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import classNames from 'classnames/bind';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import getChimeContext from '../context/getChimeContext';
import MessageType from '../types/MessageType';
import styles from './Classroom.css';
import commonob from '../constants/common.json';
const cloudName = commonob.cloudinary.cloudname;
const unsignedUploadPreset = commonob.cloudinary.preset;
const cx = classNames.bind(styles);

type Props = {
  tab: number;
  setshowImg_: (img: string) => void;
  child: any;
  title: string;
};

let chld = { is: false }
export default function FileShare(props: Props) {
  const { tab, setshowImg_, child, title } = props;
  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  
  const [messages, setMessages] = useState <MessageType[]>([]);
  const [uploadimg, setuploadimg] = useState({});
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState([]);
  const [uploadDoc, setUploadDoc] = useState(false);
  const realTimeMessages: MessageType[] = [];
  const publicChannel = commonob.pubnub.public_channel + "-" + title;
  
  useEffect(() => {

//    const realTimeMessages: MessageType[] = [];
    
      chime?.pubnub?.addListener({
        message: async (msg: any) => { 

            msg.message['channel'] = msg.channel                
            realTimeMessages.push(msg.message);
            setMessages(realTimeMessages.slice() as MessageType[]);
        }
    });

      fetchHistory(publicChannel)

  }, []);


      function fetchHistory(channel) {

    chime?.pubnub?.fetchMessages(
    {
        channels: [channel],
        count: 100
    },
    (status, response) => {
        // handle response
        response?.channels?.[channel].forEach( data => {
          
          if(data.message.type == 'chat-upload-message'){
              data.message['channel'] = data.channel            
              documentUploadMessages(data.message)              
            }

          
        })
    }
);

  }



    function documentUploadMessages(message: MessageType) { 

    if(message.name && 
          message.type === 'chat-upload-message' && 
          message.payload.attendeeId !== ""
          ) {

          realTimeMessages.push(message);
          setMessages(realTimeMessages.slice() as MessageType[]);
        chld.is = false;
    }
    return 0;
  }

  


  const onUpload = async (file: any) => {
    // e.preventDefault();
    try{
      setLoading(true)
      var url = `https://api.cloudinary.com/v1_1/${cloudName}/auto/upload`;
      var xhr = new XMLHttpRequest();
      var fd = new FormData();
      xhr.open('POST', url, true);
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

      xhr.onreadystatechange = (e) => {
        if (xhr.readyState == 4 && xhr.status == 200) {
          // File uploaded successfully
          setLoading(false)
          var response: any = JSON.parse(xhr.responseText);
          let tempPid: any = document
          tempPid.push(response)
          setDocument(tempPid)
          setuploadimg({})

          const attendeeId = chime?.configuration?.credentials?.attendeeId;

          if (response.secure_url !== '' && attendeeId) {
            
            // chime?.sendMessage('chat-upload-message', {
            //   attendeeId,
            //   message: response.secure_url,
            //   original_filename: response.original_filename
            // });


             let msg = {
              name : chime?.roster?.[attendeeId].name,
              payload: {
                  attendeeId,
                  message: response.secure_url,
                  original_filename: response.original_filename
              },
              timestampMs: Date.now(),
              type: "chat-upload-message",
              child: false
            }
            chime?.pubnub?.publish({ channel : commonob.pubnub.public_channel + "-" + title, message: msg})

          }
        }
      };
      fd.append('upload_preset', unsignedUploadPreset);
      //fd.append('public_id', "test");
      fd.append('tags', 'browser_upload'); // Optional - add tag for image admin in Cloudinary
      fd.append('file', file);
      xhr.send(fd);

    }

    catch(err){
        console.log(err)
        setLoading(false);
    }

    

  }
  
  return (
    <>
        
        <form>
                      <div className={cx('image-upload-wrap')}>
                        <input type="file" name="myImage" className={cx('file-upload-input')} onChange={(e: any) => onUpload(e.target.files[0])} />
                        <div className={cx('drag-text')}>

                          <div className={cx('drag-box')}>
                            <div className={cx('first-div')}>
                              <div className={cx('first-span')}>Upload Your File</div>
                              <div className={cx('second-span')}>{`.png .jpg .gif .pdf files only`}</div>
                            </div>

                            <div className={cx('img-div')}>
                              <img src="/icons/file-upload-image.png" />
                            </div>

                            <div className={cx('dashed-div')}>
                              <div className={cx('content')}>
                                Drag and Drop file here to upload
                      </div>
                            </div>
                            {loading ? <div className={cx('file-status')}>Uploading...</div> : null}
                          </div>

                        </div>
                      </div>
                    </form>
        

          <div className={cx('uploadMessages')} style={{ display: (tab === 2) ? 'block' : 'none' }}>
                      {messages.map((message: any, key: number) => {

                        if (message.type === 'chat-upload-message') {
                          let messageString = message.payload.message;
                          let extension = message.payload.message.split('.')[3]
                          let original_filename = message.payload.original_filename + "." + extension;

                          let filename = messageString.substr(messageString.lastIndexOf('/') + 1);

                          if (filename.match(/.(jpg|jpeg|jpe|png|gif|bmp|tif|tiff|svg)$/i)) {
                            return <div key={key} onClick={() => setshowImg_(messageString)} className={cx('uploadedFiles')} style={{ clear: "both" }}>
                              <div className={cx('cols')}>
                                <img src='/icons/image-file.png' /></div>
                              <div className={cx('cols')} style={{ paddingTop: 2, cursor: 'inherit' }}>{(original_filename.length > 24) ? (original_filename.substr(0, 23) + "...") : original_filename}</div>
                            </div>
                          } else if (filename.match(/.(mp4|3gp|avi|flv|mov|mkv|mpeg|wmv|webm)$/i)) {
                            return <div onClick={() => setshowImg_(messageString)} className={cx('uploadedFiles')} style={{ clear: "both" }}>
                              <div className={cx('cols')}>
                                <img key={key} src='/icons/File-Video-icon.png' />;
                    {/* <Video key={key} onClick={() => setshowImg_(messageString)} width="100" cloudName={cloudName} publicId={messageString}><Transformation overlay="text:arial_60:watchme" gravity="north" y="20" /></Video> */}
                              </div>
                              <div className={cx('cols')} style={{ paddingTop: 2, cursor: 'inherit' }}>{(original_filename.length > 24) ? (original_filename.substr(0, 23) + "...") : original_filename}</div>
                            </div>
                          } else if (filename.match(/.(mp3|wav|m4a|aac|aiff|amr)$/i)) {
                            return <div onClick={() => setshowImg_(messageString)} className={cx('uploadedFiles')} style={{ clear: "both" }}>
                              <div className={cx('cols')}>
                                <img key={key} src='/icons/file-audio-icon.png' />
                              </div>
                              <div className={cx('cols')} style={{ paddingTop: 2, cursor: 'inherit' }}>{(original_filename.length > 24) ? (original_filename.substr(0, 23) + "...") : original_filename}</div>
                            </div>
                          } else if (filename.match(/.(pdf|doc|docx|xls|xlsx|ppt|pptx)$/i)) {
                            return (<div onClick={() => setshowImg_(messageString)} className={cx('uploadedFiles')} style={{ clear: "both" }}>
                              <div className={cx('cols')}>
                                {(() => {
                                  if (filename.match(/.(pdf)$/i)) {
                                    return <img key={key} src='/icons/pdf-icon.png' />
                                  } else if (filename.match(/.(doc|docx)$/i)) {
                                    return <img key={key} src='/icons/docx-mac-icon.png' />
                                  } else if (filename.match(/.(xls|xlsx)$/i)) {
                                    return <img key={key} src='/icons/xlsx-mac-icon.png' />
                                  } else if (filename.match(/.(ppt|pptx)$/i)) {
                                    return <img key={key} src='/icons/ppt-file-icon.png' />
                                  }
                                })()}
                              </div>
                              <div className={cx('cols')} style={{ paddingTop: 2, cursor: 'inherit' }}>{(original_filename.length > 24) ? (original_filename.substr(0, 23) + "...") : original_filename}</div>
                            </div>)
                          } else {
                            return <div onClick={() => setshowImg_(messageString)} className={cx('uploadedFiles')} style={{ clear: "both" }}>
                              <div className={cx('cols')}>
                                <img key={key} src='/icons/random-file.png' />
                              </div>
                              <div className={cx('cols')} style={{ paddingTop: 2, cursor: 'inherit' }}>{(original_filename.length > 24) ? (original_filename.substr(0, 23) + "...") : original_filename}</div>
                            </div>
                          }

                        }
                      })}
                    </div>
    </>
  );
}
