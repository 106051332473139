// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/* eslint-disable  */

import classNames from 'classnames/bind';
import React from 'react';
import styles from './ScreenShareHeader.css';

const cx = classNames.bind(styles);

type Props = {
  onClickStopButton: () => void;
};

export default function ScreenShareHeader(props: Props) {
  const { onClickStopButton } = props;
  return (
    <div className={cx('screenShareHeader')}>

      <button
        className={cx('stopButton', 'colornone')}
        type="button"
      >
        <i className='fas fa-arrows-alt'></i>
      </button>

      <button
        className={cx('stopButton', 'marginleft')}
        type="button"
        onClick={onClickStopButton}
      >
        <i className='fas fa-pause'></i>
      </button>
    </div>
  );
}
