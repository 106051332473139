import commonob from '../constants/common.json'


// export async function getFormStack(){
    
//     const requestOptionGet = {
//         headers: {
//             'Content-Type': 'application/json',
//              'Authorization': commonob.formstack.credential
//         }
//     }
//     const response = await fetch(commonob.formstack.url + commonob.formstack.endPart , requestOptionGet)
//     const data = await response.json();
//     return data;
// }

export async function getFields(id:string) {
    const requestOptionGet = {
        headers: {
            'Content-Type': 'application/json',
             'Authorization': commonob.formstack.credential
        }
    } 
    const response = await fetch(`${commonob.formstack.url}${commonob.formstack.endPart}/${id}/fields` , requestOptionGet)
    const data = await response.json();
    return response.status === 200 ? data : [];
}

export async function mergeFields(id:string, key:string, field:object) {
    const requestOptionPost = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
             'Authorization': commonob.formstack.credential
        },
        body: JSON.stringify({field})
    } 
    const response = await fetch(`${commonob.formstack.url}/merge/${id}/${key}?download=1` , requestOptionPost)
    return response;
}