// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/* eslint-disable  */ 

import classNames from 'classnames/bind';
import React, { useContext } from 'react';

import { Redirect } from 'react-router-dom';
import routes from '../constants/routes.json';
import styles from './Home.css';

import localStorageKeys from '../constants/localStorageKeys.json';

const cx = classNames.bind(styles);
let removeItem = localStorageKeys.REMOVE_ITEMS;

export default function Home() { 

  // localStorage.clear();  
  removeItem.map( (key) =>
    localStorage.removeItem(key)
  )
  
  return (
    <div className={cx('home')}>
    {
        <Redirect to={routes.CREATE_OR_JOIN} />
    }
    </div>
  );
}
