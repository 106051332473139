// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/* eslint-disable  */ 

enum ClassMode {
  Teacher = 'Teacher',
  Student = 'Student'
}

export default ClassMode;
