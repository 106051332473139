/* eslint-disable  */

import React, { useState } from 'react';
import Modal from 'react-modal';

import classNames from 'classnames/bind';
import styles from './FormStack.css';
import { getFields, mergeFields } from '../formstack/callFormStack'
import LoadingSpinner from './LoadingSpinner';

const cx = classNames.bind(styles);

const FormStack = (props) => {

    Modal.setAppElement('body');
    const docs = props.docFS
    const [form, setForm] = useState([])
    const [field, setField] = useState({})
    const [fileName, setFileName] = useState("")
    const [mergeID, setMergeID] = useState("")
    const [mergeKEY, setMergeKEY] = useState("")
    const [isLoading, setLoading] = useState(false)
    const [submit, setSubmit] = useState(false)
    const [editClicked, setEditClicked] = useState(false)

    const handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setField({ ...field, [name]: value });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        await mergeFields(mergeID, mergeKEY, field)
        setSubmit(true)
    }

    return (
        <>
            <div>
                {(docs.length == 0) ? (<div style={{ textAlign: 'center' }}>No document found !</div>) : (<div>{docs.map(message => {
                    let original_filename = message.name;
                    let fileType = message.type;

                    return (<div key={message.key} className={cx('formStack')} style={{ clear: "both" }}>
                        <div className={cx('cols')}>
                            {(() => {
                                if (fileType.match(/.(pdf)$/i)) {
                                    return <img
                                        src='/icons/pdf-icon.png' />
                                } else if (fileType.match(/doc||docx/g)) {
                                    return <img
                                        key={message.key}
                                        src='/icons/docx-mac-icon.png' />
                                } else if (fileType.match(/.(xls|xlsx)$/i)) {
                                    return <img
                                        src='/icons/xlsx-mac-icon.png' />
                                } else if (fileType.match(/.(ppt|pptx)$/i)) {
                                    return <img
                                        key={message.key}
                                        src='/icons/ppt-file-icon.png' />
                                }
                            })()}
                        </div>

                        <div className={cx('cols')} style={{ padding: '10px 5px', cursor: 'inherit' }}>
                            {(original_filename.length > 24) ? (original_filename.substr(0, 23) + "...") : original_filename}
                        </div>

                        <div className={cx('cols')} style={{ paddingTop: 10, cursor: 'inherit', float: 'right', marginRight: '5px' }}>
                            <img
                                src='/icons/file-edit-icon.png'
                                style={{ cursor: 'pointer' }}
                                onClick={async () => {
                                    setLoading(true)
                                    setEditClicked(true)
                                    setFileName(original_filename)
                                    setForm(await getFields(message.id))
                                    setMergeID(message.id)
                                    setMergeKEY(message.key)
                                    setSubmit(false)
                                    setLoading(false)
                                }}
                            />
                        </div>

                    </div>)
                })}</div>)}

            </div>

            <Modal
                isOpen={editClicked}
                contentLabel="Open iFrame"
                className={cx('modal')}
                overlayClassName={cx('modalOverlay')}
                onRequestClose={() => { setEditClicked(false) }}
            >

                <div className={cx('mergeIframe')} >
                    <div className={cx('top')}>
                        <h1 className={cx('header')}>
                            {(fileName.length > 35) ? (fileName.substr(0, 24) + "...") : fileName}
                        </h1>
                    </div>

                    {submit ? <div className={cx('submit-msg')}>You have successfully submitted your detail.</div> : (<div className={cx('middleFrame')}>
                        {isLoading ? <LoadingSpinner /> : (
                            <form className={cx('mergeForm')}>
                                {form.length == 0 ?
                                    <div className={cx('errorMsg')}>Something went wrong!,Please try after some time.</div>
                                    : (
                                        <>
                                            {form.map(item => {
                                                return (

                                                    <label className={cx('fieldLabel')} key={item.key}>{item.name}:
                                                        <input className={cx('fieldInput')} type="text" name={item.name} onChange={handleChange} />
                                                    </label>
                                                )
                                            }
                                            )}
                                            <button onClick={handleSubmit} className={cx('submitForm')}>Submit</button>
                                        </>
                                    )}

                            </form>
                        )}
                    </div>)}
                    <div className={cx('closeButton')}>
                        <div className={cx('buttons')}>
                            <button
                                type="button"
                                className={cx('cancelButton')}
                                onClick={() => { setEditClicked(false) }}
                            >Close
                        </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default FormStack;