// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/* eslint-disable  */ 

enum ViewMode {
  Room,
  ScreenShare
}

export default ViewMode;
